import { Icon } from "@iconify/react";
import { Button, Drawer, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EcnButton from "../StyledComponents/V2/EcnButton";
import EcnCheckboxFilled from "../StyledComponents/V2/EcnCheckboxFilled";
import Question from "./Question";
import question_waiting from "../../assets/lottie/question_waiting.json";
import { jobCreate } from "../../redux/slices/JobSlices/jobCreateSlices";
import { enqueueSnackbar } from "notistack";

const AllQuestion: React.FC<{
  jobData: any;
  setJobData: any;
  setOpen: any;
  open: any;
  setStep: any;
}> = ({ jobData, setJobData, setOpen, open, setStep }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") || "";
  const redirect = searchParams.get("redirect") || "";

  const { questionData, loading } = useAppSelector(
    (state: any) => state.generateQuestionData
  );

  const { jobData: successJobData, loading: jobCreateDataLoading } =
    useAppSelector((state: any) => state.jobCreateData);

  const [data, setData] = useState<any>([]);
  const [work, setWork] = useState<any>([]);
  const [workIndex, setWorkIndex] = useState(0);
  const [scoreList, setScoreList] = useState<any>([workIndex]);
  const [click, setClick] = useState(false);

  useEffect(() => {
    if (successJobData && click) {
      enqueueSnackbar("job draft successfully!", {
        variant: "success",
      });
      navigate("/jobs");
    }
  }, [successJobData]);

  const closedDrawerHandler = () => {
    setOpen(false);
  };

  const selectHandler = (item: any) => {
    // Check if the item already exists in the selectedItems array
    const index = data.findIndex(
      (selectedItem: any) => selectedItem.question_text === item.question_text
    );

    if (index !== -1) {
      // If the item already exists, remove it from the selectedItems array
      setData((prevSelectedItems: any) =>
        prevSelectedItems.filter((_: any, idx: any) => idx !== index)
      );
    } else {
      // If the item doesn't exist, add it to the selectedItems array
      setData((prevSelectedItems: any) => [...prevSelectedItems, item]);
    }
  };

  const addHandler = () => {
    let work = data.map((question: any) => ({
      key: "",
      name: question.question_text || "",
      options: question.options || null,
      required: question.required || false,
      save: question.save || false,
      type:
        question.question_type === "text"
          ? 0
          : question.question_type === "multiple-select"
          ? 5
          : 2,

      values: question.values || [""],
    }));

    setWork(work);

    const number = data.length; // Use the length property directly
    const newArray = Array.from({ length: number }, (_, index) => index);

    setScoreList(newArray?.length === 0 ? [0] : newArray);
    setWorkIndex(number - 1); // Adjust the workIndex based on the length
    setOpen(false);
    setJobData({
      ...jobData,
      job_questions: work,
    });
  };

  const clearHandler = () => {
    setData([]);
  };

  function checkOptionsFilled(array: any) {
    for (const obj of array) {
      if ((obj?.type === 2 || obj?.type === 5) && obj?.options?.length === 0) {
        return false;
      }
    }
    return true;
  }

  const handleClick = () => {
    if (checkOptionsFilled(jobData?.job_questions)) {
      navigate(`?view=preview${id === "" ? "" : "&id=" + id}`);
    } else {
      enqueueSnackbar(
        "Add atleast one option in multi select and select question ",
        {
          variant: "error",
        }
      );
    }
  };

  const backHandler = () => {
    setStep(3);
    navigate(`?view=description${id === "" ? "" : "&id=" + id}`);
  };

  const draftHandler = () => {
    setClick(true);
    dispatch(
      jobCreate({
        ...jobData,
        draft_status: {
          is_draft: true,
          draft_state: 4,
        },
      })
    );
  };

  const skipHandler = () => {
    setJobData({
      ...jobData,
      job_questions: [],
    });
    navigate(`?view=preview${id === "" ? "" : "&id=" + id}`);
  };

  return (
    <div className="question">
      <div className="question-body">
        <Question
          jobData={jobData}
          setJobData={setJobData}
          setWork={setWork}
          work={work}
          setScoreList={setScoreList}
          scoreList={scoreList}
          setWorkIndex={setWorkIndex}
          workIndex={workIndex}
        />
      </div>

      <div className="bottom-button">
      <div className="bottom-container">
        <div className="left">
          <EcnButton className="back" onClick={backHandler}>
            <Icon icon="lucide:arrow-left" /> Back
          </EcnButton>

          <EcnButton
            className="save"
            onClick={draftHandler}
            disabled={jobCreateDataLoading}
          >
            <Icon icon="lucide:bookmark" /> Save draft
          </EcnButton>
        </div>
        <div>
          <EcnButton
            className="next"
            variant="outlined"
            style={{ border: "1px solid rgba(0,0,0,0.2)", marginRight: "20px" }}
            onClick={skipHandler}
          >
            Skip
          </EcnButton>
          {redirect === "" && (
            <EcnButton
              className="next"
              variant="contained"
              onClick={handleClick}
            >
              <Icon
                icon="lucide:arrow-right"
                className="icon"
                width={18}
                height={18}
              />{" "}
              Next
            </EcnButton>
          )}
          {redirect != "" && (
            <EcnButton
              className="next"
              variant="contained"
              onClick={() => navigate(`?view=preview&tab=question`)}
              style={{ width: "180px" }}
            >
              Save Changes
            </EcnButton>
          )}
        </div>
        </div>
      </div>

      <Drawer
        anchor="right"
        open={open}
        onClose={closedDrawerHandler}
        className="open-question"
      >
        <div className="top">
          <Button onClick={closedDrawerHandler} className="back">
            <Icon icon="material-symbols:arrow-back" width={24} height={24} />{" "}
            Generate with AI
          </Button>
          <IconButton onClick={closedDrawerHandler}>
            <Icon icon="ic:baseline-close" />{" "}
          </IconButton>
        </div>
        {!loading ? (
          <>
            {" "}
            <h3 className="title">
              {questionData?.length > 0 && (
                <Icon icon="lucide:sparkles" width={24} height={24} />
              )}{" "}
              {questionData?.length > 0
                ? questionData?.length +
                  " potential questions generated by Elevate AI"
                : ""}
            </h3>
            <div className="drawer-body">
              {questionData?.map((item: any) => (
                <div
                  className={
                    data.some(
                      (selectedItem: any) =>
                        selectedItem.question_text === item.question_text
                    )
                      ? "question-item active"
                      : "question-item"
                  }
                  onClick={() => selectHandler(item)}
                >
                  {item?.question_type === "text" ? (
                    <Button className="button">
                      <Icon icon="lucide:equal" width={20} height={20} /> Short
                      answer
                    </Button>
                  ) : item?.question_type === "single-select" ? (
                    <Button className="button">
                      <Icon
                        icon="akar-icons:radio-fill"
                        width={20}
                        height={20}
                      />{" "}
                      Select
                    </Button>
                  ) : (
                    <Button className="button">
                      <Icon icon="ic:round-check-box" width={20} height={20} />{" "}
                      Multi- select
                    </Button>
                  )}

                  <div className="item">
                    <EcnCheckboxFilled
                      checked={data.some(
                        (selectedItem: any) =>
                          selectedItem.question_text === item.question_text
                      )}
                    />
                    <div className="left">
                      <h2>{item?.question_text}</h2>

                      {item?.options?.length != 0 && (
                        <ul className="ul">
                          {item?.options?.map((opt: any) => (
                            <li>-{opt}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="bottom-button">
              <Button className="clear" onClick={clearHandler}>
                Clear
              </Button>
              <Button className="add" onClick={addHandler}>
                Add
              </Button>
            </div>
          </>
        ) : (
          <div className="fallback">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: question_waiting,
              }}
              height={300}
              width={400}
            />
            <h1> Hang on tight!</h1>

            <p>We are generating questions for you to find right candidates</p>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default AllQuestion;
