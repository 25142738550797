import { LineChart } from "@mui/x-charts/LineChart";
import React from "react";
import { useAppSelector } from "../../../../redux/hooks";
import ToolTips from "./toolTips";
import Fallback from "../../Fallback";

const Line = () => {
  const { promoAnalytics, loading } = useAppSelector(
    (state: any) => state.promoAnalyticsData
  );

  // Define the order of days of the week
  const orderOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // Initialize arrays for reach, view, and apply percentages
  const reachArray = [] as any;
  const viewArray = [] as any;
  const applyPercentageArray = [] as any;

  // Iterate through the days of the week in order
  orderOfWeek.forEach((day) => {
    const dayData = promoAnalytics?.click_through_count?.results[day];

    // Calculate the percentage value for total_apply_count
    const applyPercentage =
      (dayData.total_apply_count / promoAnalytics.total_reach_count) * 100;

    // Push values to respective arrays
    reachArray.push(dayData.total_reach_count || 0);
    viewArray.push(dayData.total_view_count || 0);
    applyPercentageArray.push(applyPercentage || 0);
  });

  function allFieldsZero(data: any) {
    for (const day in data) {
      const counts = data[day];
      if (Object.values(counts).some((count) => count !== 0)) {
        return false; // At least one field is non-zero
      }
    }
    return true; // All fields are zero
  }
  const areAllFieldsZero = allFieldsZero(
    promoAnalytics?.click_through_count?.results
  );

  return (
    <div className="line">
      <h1>
        Click Through Rate{" "}
        <ToolTips title={promoAnalytics?.click_through_count?.tooltip} />
      </h1>
      {!areAllFieldsZero ? (
        <>
          <LineChart
            sx={{
              "& .MuiAreaElement-series-Reach": {
                fill: "url('#myGradient')",
              },
              "& .MuiAreaElement-series-View": {
                fill: "url('#myGradient2')",
              },
              "& .MuiAreaElement-series-apply": {
                fill: "url('#myGradient3')",
              },
            }}
            margin={{ top: 10, left: 50, right: 40 }}
            xAxis={[
              {
                scaleType: "band",
                data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
              },
            ]}
            series={[
              {
                id: "apply",
                data: applyPercentageArray,

                area: true,
                showMark: true,
                yAxisKey: "rightAxisId",
                color: "#0095FF",
              },
              {
                id: "Reach",
                data: reachArray,

                area: true,
                showMark: true,
                color: "#95A9EA",

                yAxisKey: "leftAxisId",
              },
              {
                id: "View",
                data: viewArray,

                area: true,
                showMark: true,
                color: "#2563EB",
                yAxisKey: "leftAxisId",
              },
            ]}
            yAxis={[
              { id: "leftAxisId" },
              {
                id: "rightAxisId",
              },
            ]}
            rightAxis="rightAxisId"
            height={240}
          >
            <defs>
              <linearGradient id="myGradient" gradientTransform="rotate(90)">
                <stop offset="20%" stop-color="#BAC6F6" stop-opacity="0.35" />
                <stop offset="100%" stop-color="white" stop-opacity="0.01" />
              </linearGradient>
            </defs>

            <defs>
              <linearGradient id="myGradient2" gradientTransform="rotate(90)">
                <stop offset="20%" stop-color="#6080EF" stop-opacity="0.35" />
                <stop offset="100%" stopColor="#ffffff" stop-opacity="0.01" />
              </linearGradient>
            </defs>

            <defs>
              <linearGradient id="myGradient3" gradientTransform="rotate(90)">
                <stop offset="20%" stopColor="#4AB3FF" stop-opacity="0.35" />
                <stop offset="100%" stopColor="#ffffff" stop-opacity="0.01" />
              </linearGradient>
            </defs>
          </LineChart>

          <div className="pie-legend-list">
            {" "}
            <div className="pie-legend-list-item">
              <div className="dots" style={{ background: `#0095FF` }}></div>
              <p>Click (% ) </p>
            </div>
            <div className="pie-legend-list-item">
              <div className="dots" style={{ background: `#95A9EA` }}></div>
              <p>Applications </p>
            </div>
            <div className="pie-legend-list-item">
              <div className="dots" style={{ background: `#2563EB` }}></div>
              <p>View </p>
            </div>
          </div>
        </>
      ) : (
        <Fallback message="No  click through rate data available yet" />
      )}
    </div>
  );
};

export default Line;
