import { Icon } from "@iconify/react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Backdrop, Button, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactQuill from "react-quill";
import { useNavigate, useSearchParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { generateJobDescription } from "../../redux/slices/JobSlices/generateJobDescriptionSlice";
import { jobCreate } from "../../redux/slices/JobSlices/jobCreateSlices";
import EcnButton from "../StyledComponents/EcnButton";



const Description: React.FC<{
  jobData: any;
  setJobData: any;
  step: any;
  setStep: any;
  jobDataChanged: any;
  setJobDataChanged: any;
}> = ({
  jobData,
  setJobData,
  step,
  setStep,
  jobDataChanged,
  setJobDataChanged,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") || "";
  const redirect = searchParams.get("redirect") || "";

  const quillRef = useRef<ReactQuill>(null);

  const [emailText, setEmailText] = useState<any>("");
  const [generateAI, setGenerateAI] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [clickGenerateAI, setClickGenerateAI] = useState<boolean>(false);
  const [active, setActive] = useState<number>(1);
  const [click, setClick] = useState(false);

  const { firmDetailsData, errorData } = useAppSelector(
    (state: any) => state.getFirmDetailsData
  );

  const { jobData: successJobData, loading } = useAppSelector(
    (state: any) => state.jobCreateData
  );

  const {
    jobDescriptionData,
    loading: jobDescriptionLoading,
    error: jobDescriptionError,
  } = useAppSelector((state: any) => state.jobDescription);

  useEffect(() => {
    if (!jobDescriptionLoading && jobDescriptionData && clickGenerateAI) {
      setGenerateAI(true);
      setJobDataChanged(false);
    }
  }, [jobDescriptionData, clickGenerateAI, jobDescriptionLoading]);

  useEffect(() => {
    if (successJobData && click) {
      enqueueSnackbar("job draft successfully!", {
        variant: "success",
      });
      navigate("/jobs");
    }
  }, [successJobData]);

  const descriptionHandler = (content: any) => {
    setJobData({
      ...jobData,
      description: content,
    });
  };

  const generateAIHandler = () => {
    dispatch(
      generateJobDescription({
        job_title: jobData?.job_title,
        job_roles: jobData?.job_roles,
        job_industries: jobData?.industries,
        job_skills: jobData?.skills,
        job_location: `${jobData?.address?.cities?.join(", ") ?? ""}, ${
          jobData?.address?.state ?? ""
        }, ${jobData?.address?.country ?? ""}`,
        experince: jobData?.experience,
        role_type: jobData?.role_type,
        firm_overview: firmDetailsData?.description,
      })
    );
    setClickGenerateAI(true);
  };

  const closeHandler = () => {
    setGenerateAI(false);
  };

  const editHandler = () => {
    const desc =
      active === 1
        ? jobDescriptionData?.Casual
        : active === 2
        ? jobDescriptionData?.Formal
        : jobDescriptionData?.Academic;
    if (jobData?.description && jobData?.description !== "<p><br></p>") {
      setOpenConfirm(true);
     
    } else {
      setJobData({ ...jobData, description: desc });

      setGenerateAI(false);
    }
  };

  const checkAiDraftHandler = () => {
    setGenerateAI(true);
  };

  const handleClick = () => {
    navigate(`?view=questions${id === "" ? "" : "&id=" + id}`);
  };

  const backHandler = () => {
    setStep(step - 1);
    navigate(`?view=skill${id === "" ? "" : "&id=" + id}`);
  };

  const draftHandler = () => {
    setClick(true);
    dispatch(
      jobCreate({
        ...jobData,
        draft_status: {
          is_draft: true,
          draft_state: 3,
        },
      })
    );
  };

  const confirmCloseHandler = () => {
    setOpenConfirm(false);
  };

  const confirmHandler = () => {
    const desc =
      active === 1
        ? jobDescriptionData?.Casual
        : active === 2
        ? jobDescriptionData?.Formal
        : jobDescriptionData?.Academic;
    setJobData({ ...jobData, description: desc });
    setGenerateAI(false);
    setClickGenerateAI(false);
    setOpenConfirm(false);
  };

  return (
    <div className="description">
      <div className="body">
        <div className="row">
          <p className="label">Job description*</p>

          {jobData && (
            <ReactQuill
              onChange={descriptionHandler}
              value={jobData?.description || ""}
              ref={quillRef}
              theme="snow"
              bounds={".app"}
              className="quill-candidatesdetail"
              modules={modules}
              formats={formats}
              placeholder="Start writing from here"
            />
          )}

          <div className="generate-ai-btn">
            {!jobDescriptionData || jobDataChanged || jobDescriptionLoading ? (
              <LoadingButton
                onClick={generateAIHandler}
                variant="contained"
                className="btn-contained"
                loadingPosition="end"
                loading={jobDescriptionLoading}
                sx={{
                  width:"230px",
                  "&.MuiLoadingButton-loading": {
                    "& .MuiLoadingButton-loadingIndicator": {
                      marginLeft: "48px !important", // Add margin after the loading indicator
                    },
                  },
                }}
              >
                Generate with AI
                <Icon icon="fluent-emoji:sparkles" className="icon" />
              </LoadingButton>
            ) : (
              <Button
                onClick={checkAiDraftHandler}
                variant="contained"
                className="btn-contained check-draft"
              >
                Check AI Drafts
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="bottom-button">
      <div className="bottom-container">
        <div className="left">
          <EcnButton className="back" onClick={backHandler}>
            <Icon icon="lucide:arrow-left" /> Back
          </EcnButton>

          <EcnButton className="save" onClick={draftHandler} disabled={loading}>
            <Icon icon="lucide:bookmark" /> Save draft
          </EcnButton>
        </div>
        {redirect === "" && (
          <EcnButton
            className="next"
            variant="contained"
            onClick={handleClick}
            disabled={jobData?.description === ""}
          >
            <Icon
              icon="lucide:arrow-right"
              className="icon"
              width={18}
              height={18}
            />{" "}
            Next
          </EcnButton>
        )}

        {redirect != "" && (
          <EcnButton
            className="next"
            variant="contained"
            onClick={() => navigate(`?view=${redirect}`)}
            style={{ width: "180px" }}
          >
            Save Changes
          </EcnButton>
        )}
      </div>
      </div>

      <Dialog
        open={generateAI}
        onClose={closeHandler}
        BackdropProps={{
          timeout: 500,
          sx: { backgroundColor: "rgba(0, 0, 0, 0.3) !important" } // Add your backdrop styles here
        }}
        className="ai-job-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1440px",
              borderRadius: "22px",
              height: "97vh",
              maxHeight: "696px",
            },
          },
        }}
      >
        <DialogTitle className="heading">
          <Icon
            icon="bitcoin-icons:cross-outline"
            className="cross"
            onClick={closeHandler}
          />
        </DialogTitle>
        <DialogContent className="container">
          <div className="body">
            <div className="left-body">
              <div className="card-description">
                <img
                  src="https://ecn.blob.core.windows.net/ecn/images/enterprise/elevate_ai.png"
                  alt=""
                  className="elevate-ai"
                />
                <h3>
                  Use Onefinnet AI to draft Job Description for your job post in
                  seconds!
                </h3>
                <p>
                  <Tooltip
                    title={
                      <ol>
                        <li>
                          Onefinnet AI will generate 3 versions of Job Description
                          with 3 different tones.
                        </li>
                        <li>
                          Select any one of the draft suggested by Onefinnet AI on
                          your left sidebar.
                        </li>
                        <li>
                          After draft selection click on Edit button on bottom
                          right corner to edit the draft and use it.
                        </li>
                      </ol>
                    }
                    arrow
                    placement="right"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          color: "rgba(0, 0, 0, 1)",
                          padding: "14px 9px 13px 12px",
                          paddingLeft: "30px",
                          fontWeight: "400",
                          fontSize: "10px",
                          borderRadius: "5px",
                          maxWidth: "264px",
                          backgroundColor: "#EDF3FE;",
                        },
                      },
                      arrow: {
                        sx: {
                          fontSize: "30px",
                          color: "#EDF3FE;",
                        },
                      },
                    }}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, 6],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <span>How to use it ?</span>
                  </Tooltip>
                </p>
              </div>
              <div
                className={active === 1 ? "card active" : "card"}
                onClick={() => setActive(1)}
              >
                <div className="top-section"></div>
                <div className="bottom-section">
                  <h3>Causal - Informative Tone</h3>
                  <p>
                    Clear, Concise and engaging tone, relatable and easy to
                    understand.
                  </p>
                  <p className="words">
                    {jobDescriptionData?.Casual?.length} Words
                  </p>
                </div>
              </div>
              <div
                className={active === 2 ? "card active" : "card"}
                onClick={() => setActive(2)}
              >
                <div className="top-section"></div>
                <div className="bottom-section">
                  <h3>Business Formal Tone</h3>
                  <p>
                    Professional and Informative with complex sentences and
                    vocabulary.
                  </p>
                  <p className="words">
                    {jobDescriptionData?.Formal?.length} Words
                  </p>
                </div>
              </div>
              <div
                className={active === 3 ? "card active" : "card"}
                onClick={() => setActive(3)}
              >
                <div className="top-section"></div>
                <div className="bottom-section">
                  <div>
                    <h3>Academic and Tech Savvy Tone</h3>
                    <p className="academic">
                      Tech Savvy & Trendy Tone for digital age.
                    </p>
                  </div>

                  <p className="words">
                    {jobDescriptionData?.Academic?.length} Words
                  </p>
                </div>
              </div>
            </div>
            <div className="right-body">
              <div className="container">
                <ReactMarkdown
                  className="markdown-data"
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                >
                  {active === 1
                    ? jobDescriptionData?.Casual
                    : active === 2
                    ? jobDescriptionData?.Formal
                    : jobDescriptionData?.Academic}
                </ReactMarkdown>
              </div>
              <div className="markdown-bottom">
                <Button
                  onClick={editHandler}
                  variant="contained"
                  className="btn-contained"
                  disableElevation={true}
                >
                  Edit
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={confirmCloseHandler}
        className="confirm-dialog"

        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "505px",
              borderRadius: "12px",
            },
          },
        }}
      >
        <DialogContent>
          <h3>Are you sure ?</h3>
          <p>
            Your previous Job Description will be replaced with your selected
            draft !
          </p>
          <img
            src={
              "https://ecn.blob.core.windows.net/ecn/images/enterprise/ai_blue.png"
            }
            alt=""
          />
          <div className="button-container">
            <Button
              className="cancel"
              onClick={confirmCloseHandler}
              variant="outlined"
              disableElevation={true}
            >
              {" "}
              Cancel
            </Button>
            <Button
              className="confirm"
              onClick={confirmHandler}
              variant="contained"
              disableElevation={true}
            >
              Confirm
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Description;
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
