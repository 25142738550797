import { TourProvider } from "@reactour/tour";

const stepsDesktop = [
    {
        selector: ".feature-1",
        content:
            <>
                <h6>Step 1</h6>
                <p>
                    Begin your journey by creating a job listing
                </p>
                <svg className="arrow1" xmlns="http://www.w3.org/2000/svg" width="64" height="60" viewBox="0 0 64 60" fill="none">
                    <path d="M0.520216 2.32734C1.12347 0.211214 1.28207 -0.0442877 2.81665 0.502403C4.95219 1.26886 7.068 2.15091 9.1268 3.21582C17.9144 7.77054 25.9145 13.8018 33.5064 20.5512C36.5053 23.2172 39.3586 26.1091 42.178 29.0252C43.6862 30.5853 45.0167 32.3741 46.3166 34.1441C49.5599 38.5838 52.6693 43.099 54.9515 48.1043C55.7606 49.8802 56.8324 51.5698 57.8171 53.281C58.0697 53.7297 58.359 54.2535 58.9644 54.1453C59.031 53.9759 59.1846 53.7849 59.1416 53.6532C58.6437 52.1216 58.1458 50.59 57.6044 49.0692C56.2476 45.3101 54.849 41.5403 53.5147 37.7651C53.3045 37.1847 53.2089 36.5021 53.2754 35.9268C53.3301 35.502 53.6604 34.9613 54.0082 34.8749C54.3561 34.7885 55.013 35.1104 55.3062 35.4488C55.7835 36.0208 56.1316 36.7462 56.4186 37.434C57.3561 39.7474 58.271 42.0769 59.1649 44.4011C60.5624 48.0499 61.9164 51.7095 63.293 55.3529C63.7168 56.4707 64.1615 57.5938 63.6201 58.6508C61.2936 59.9086 58.8481 59.4247 56.2798 58.1964C54.2047 57.2041 52.0872 56.3435 49.9861 55.4104C49.5122 55.2012 49.0433 54.9276 48.6084 54.6297C47.1031 53.5749 45.5261 52.7541 43.8436 52.1914C42.7846 51.8324 41.7311 51.2663 40.7195 50.711C40.3004 50.483 39.9417 50.0291 39.544 49.664C39.7942 49.4648 39.9172 49.255 40.0539 49.279C41.3213 49.4282 42.618 49.4751 43.8798 49.8313C45.5499 50.2811 47.2115 50.8384 48.8274 51.5705C50.7614 52.4608 52.6845 52.8108 54.5838 53.0561C55.0157 52.4427 54.5926 51.9943 54.2853 51.5645C52.104 48.395 49.9002 45.2417 47.7511 42.0695C44.1586 36.7834 39.9721 32.0032 35.5998 27.4169C34.7039 26.4743 33.6674 25.6933 32.689 24.8503C30.6312 23.0946 28.5152 21.4007 26.5172 19.5616C22.5806 15.9424 18.5192 12.5545 13.9723 9.91982C12.8709 9.28665 11.849 8.45449 10.7831 7.77558C8.25491 6.17357 5.68435 4.70333 2.95461 3.63109C2.10627 3.30407 1.32792 2.76461 0.520216 2.32734Z" fill="white" />
                </svg>
            </>
    },
    {
        selector: ".feature-2",
        content:
            <section className="right-text">
                <h6>Step 2</h6>
                <p>
                    Analyze your overall applicant data and access detailed analytics for each job listing
                </p>
                <svg className="arrow2" xmlns="http://www.w3.org/2000/svg" width="64" height="60" viewBox="0 0 64 60" fill="none">
                    <path d="M63.472 2.32734C62.8687 0.211214 62.7101 -0.0442877 61.1755 0.502403C59.04 1.26886 56.9242 2.15091 54.8654 3.21582C46.0778 7.77054 38.0777 13.8018 30.4858 20.5512C27.4869 23.2172 24.6336 26.1091 21.8142 29.0252C20.306 30.5853 18.9755 32.3741 17.6756 34.1441C14.4323 38.5838 11.3229 43.099 9.04069 48.1043C8.23156 49.8802 7.15981 51.5698 6.17505 53.281C5.9225 53.7297 5.63321 54.2535 5.02781 54.1453C4.96121 53.9759 4.80762 53.7849 4.85058 53.6532C5.34847 52.1216 5.84641 50.59 6.38779 49.0692C7.74463 45.3101 9.14321 41.5403 10.4775 37.7651C10.6877 37.1847 10.7833 36.5021 10.7168 35.9268C10.6621 35.502 10.3318 34.9613 9.98394 34.8749C9.63607 34.7885 8.97921 35.1104 8.68602 35.4488C8.20867 36.0208 7.86059 36.7462 7.57354 37.434C6.63608 39.7474 5.72122 42.0769 4.82724 44.4011C3.42977 48.0499 2.0758 51.7095 0.699223 55.3529C0.275401 56.4707 -0.169328 57.5938 0.372044 58.6508C2.69858 59.9086 5.14412 59.4247 7.71237 58.1964C9.78753 57.2041 11.905 56.3435 14.0061 55.4104C14.48 55.2012 14.9488 54.9276 15.3838 54.6297C16.8891 53.5749 18.4661 52.7541 20.1486 52.1914C21.2076 51.8324 22.2611 51.2663 23.2727 50.711C23.6918 50.483 24.0505 50.0291 24.4482 49.664C24.198 49.4648 24.0749 49.255 23.9383 49.279C22.6709 49.4282 21.3742 49.4751 20.1124 49.8313C18.4423 50.2811 16.7807 50.8384 15.1648 51.5705C13.2308 52.4608 11.3077 52.8108 9.40836 53.0561C8.97646 52.4427 9.39957 51.9943 9.70689 51.5645C11.8882 48.395 14.092 45.2417 16.2411 42.0695C19.8336 36.7834 24.0201 32.0032 28.3924 27.4169C29.2883 26.4743 30.3248 25.6933 31.3032 24.8503C33.361 23.0946 35.477 21.4007 37.475 19.5616C41.4116 15.9424 45.473 12.5545 50.0199 9.91982C51.1213 9.28665 52.1432 8.45449 53.209 7.77558C55.7373 6.17357 58.3078 4.70333 61.0376 3.63109C61.8859 3.30407 62.6643 2.76461 63.472 2.32734Z" fill="white" />
                </svg>
            </section>,
    },
    {
        selector: ".feature-3",
        content:
            <section className="step-3">
                <h6>Step 3</h6>
                <p>
                    Host your job listings on your company's dedicated Private Job Board.
                </p>
                <svg className="arrow1" xmlns="http://www.w3.org/2000/svg" width="64" height="60" viewBox="0 0 64 60" fill="none">
                    <path d="M0.520216 2.32734C1.12347 0.211214 1.28207 -0.0442877 2.81665 0.502403C4.95219 1.26886 7.068 2.15091 9.1268 3.21582C17.9144 7.77054 25.9145 13.8018 33.5064 20.5512C36.5053 23.2172 39.3586 26.1091 42.178 29.0252C43.6862 30.5853 45.0167 32.3741 46.3166 34.1441C49.5599 38.5838 52.6693 43.099 54.9515 48.1043C55.7606 49.8802 56.8324 51.5698 57.8171 53.281C58.0697 53.7297 58.359 54.2535 58.9644 54.1453C59.031 53.9759 59.1846 53.7849 59.1416 53.6532C58.6437 52.1216 58.1458 50.59 57.6044 49.0692C56.2476 45.3101 54.849 41.5403 53.5147 37.7651C53.3045 37.1847 53.2089 36.5021 53.2754 35.9268C53.3301 35.502 53.6604 34.9613 54.0082 34.8749C54.3561 34.7885 55.013 35.1104 55.3062 35.4488C55.7835 36.0208 56.1316 36.7462 56.4186 37.434C57.3561 39.7474 58.271 42.0769 59.1649 44.4011C60.5624 48.0499 61.9164 51.7095 63.293 55.3529C63.7168 56.4707 64.1615 57.5938 63.6201 58.6508C61.2936 59.9086 58.8481 59.4247 56.2798 58.1964C54.2047 57.2041 52.0872 56.3435 49.9861 55.4104C49.5122 55.2012 49.0433 54.9276 48.6084 54.6297C47.1031 53.5749 45.5261 52.7541 43.8436 52.1914C42.7846 51.8324 41.7311 51.2663 40.7195 50.711C40.3004 50.483 39.9417 50.0291 39.544 49.664C39.7942 49.4648 39.9172 49.255 40.0539 49.279C41.3213 49.4282 42.618 49.4751 43.8798 49.8313C45.5499 50.2811 47.2115 50.8384 48.8274 51.5705C50.7614 52.4608 52.6845 52.8108 54.5838 53.0561C55.0157 52.4427 54.5926 51.9943 54.2853 51.5645C52.104 48.395 49.9002 45.2417 47.7511 42.0695C44.1586 36.7834 39.9721 32.0032 35.5998 27.4169C34.7039 26.4743 33.6674 25.6933 32.689 24.8503C30.6312 23.0946 28.5152 21.4007 26.5172 19.5616C22.5806 15.9424 18.5192 12.5545 13.9723 9.91982C12.8709 9.28665 11.849 8.45449 10.7831 7.77558C8.25491 6.17357 5.68435 4.70333 2.95461 3.63109C2.10627 3.30407 1.32792 2.76461 0.520216 2.32734Z" fill="white" />
                </svg>
            </section>
    },
    {
        selector: ".latest-candidates",
        content:
            <section className="step-4">
                <h6>Step 4</h6>
                <p>
                    View the most recent applicants using our AI rating to quickly verify the applications for every position.
                </p>
                <svg className="arrow1" xmlns="http://www.w3.org/2000/svg" width="64" height="60" viewBox="0 0 64 60" fill="none">
                    <path d="M0.520216 2.32734C1.12347 0.211214 1.28207 -0.0442877 2.81665 0.502403C4.95219 1.26886 7.068 2.15091 9.1268 3.21582C17.9144 7.77054 25.9145 13.8018 33.5064 20.5512C36.5053 23.2172 39.3586 26.1091 42.178 29.0252C43.6862 30.5853 45.0167 32.3741 46.3166 34.1441C49.5599 38.5838 52.6693 43.099 54.9515 48.1043C55.7606 49.8802 56.8324 51.5698 57.8171 53.281C58.0697 53.7297 58.359 54.2535 58.9644 54.1453C59.031 53.9759 59.1846 53.7849 59.1416 53.6532C58.6437 52.1216 58.1458 50.59 57.6044 49.0692C56.2476 45.3101 54.849 41.5403 53.5147 37.7651C53.3045 37.1847 53.2089 36.5021 53.2754 35.9268C53.3301 35.502 53.6604 34.9613 54.0082 34.8749C54.3561 34.7885 55.013 35.1104 55.3062 35.4488C55.7835 36.0208 56.1316 36.7462 56.4186 37.434C57.3561 39.7474 58.271 42.0769 59.1649 44.4011C60.5624 48.0499 61.9164 51.7095 63.293 55.3529C63.7168 56.4707 64.1615 57.5938 63.6201 58.6508C61.2936 59.9086 58.8481 59.4247 56.2798 58.1964C54.2047 57.2041 52.0872 56.3435 49.9861 55.4104C49.5122 55.2012 49.0433 54.9276 48.6084 54.6297C47.1031 53.5749 45.5261 52.7541 43.8436 52.1914C42.7846 51.8324 41.7311 51.2663 40.7195 50.711C40.3004 50.483 39.9417 50.0291 39.544 49.664C39.7942 49.4648 39.9172 49.255 40.0539 49.279C41.3213 49.4282 42.618 49.4751 43.8798 49.8313C45.5499 50.2811 47.2115 50.8384 48.8274 51.5705C50.7614 52.4608 52.6845 52.8108 54.5838 53.0561C55.0157 52.4427 54.5926 51.9943 54.2853 51.5645C52.104 48.395 49.9002 45.2417 47.7511 42.0695C44.1586 36.7834 39.9721 32.0032 35.5998 27.4169C34.7039 26.4743 33.6674 25.6933 32.689 24.8503C30.6312 23.0946 28.5152 21.4007 26.5172 19.5616C22.5806 15.9424 18.5192 12.5545 13.9723 9.91982C12.8709 9.28665 11.849 8.45449 10.7831 7.77558C8.25491 6.17357 5.68435 4.70333 2.95461 3.63109C2.10627 3.30407 1.32792 2.76461 0.520216 2.32734Z" fill="white" />
                </svg>
            </section>
    },
    {
        selector: ".latest-candidates",
        content:
            <section className="very-right">
                <h6>Step 4</h6>
                <p>
                    Send emails to candidates directly with just one click.
                </p>
                <svg className="arrow3" xmlns="http://www.w3.org/2000/svg" width="50" height="73" viewBox="0 0 50 73" fill="none">
                    <path d="M47.0454 0.775919C49.233 1.01293 49.5117 1.12608 49.2321 2.73097C48.8375 4.96531 48.3257 7.19974 47.624 9.40887C44.6196 18.8397 40.0269 27.7439 34.6573 36.3671C32.5364 39.7734 30.1682 43.0743 27.7704 46.3459C26.4876 48.0961 24.9494 49.7096 23.4245 51.2899C19.5966 55.2368 15.6718 59.0644 11.1241 62.1596C9.51042 63.2572 8.02621 64.599 6.50602 65.8588C6.10652 66.1835 5.6391 66.5571 5.84801 67.1355C6.02625 67.1726 6.24047 67.2917 6.36299 67.2271C7.78847 66.4775 9.21391 65.728 10.6214 64.9374C14.0971 62.9649 17.5764 60.9494 21.0719 58.9964C21.6084 58.6912 22.2651 58.4816 22.8433 58.4499C23.2712 58.432 23.8599 58.6662 24.0039 58.9945C24.1478 59.3228 23.9415 60.0246 23.6576 60.3707C23.1744 60.9379 22.5183 61.4035 21.8889 61.8027C19.7672 63.1175 17.6257 64.4129 15.4861 65.6867C12.1259 67.6806 8.74763 69.6335 5.38918 71.6059C4.35911 72.2125 3.32731 72.8406 2.19406 72.4857C0.561194 70.4051 0.624871 67.913 1.40157 65.1741C2.02897 62.9611 2.51939 60.7287 3.08408 58.5001C3.21015 57.9977 3.40064 57.4893 3.62076 57.0103C4.40599 55.3484 4.94857 53.6554 5.21883 51.902C5.39377 50.7975 5.77365 49.6636 6.15005 48.5727C6.30398 48.1211 6.69072 47.6909 6.98342 47.2372C7.222 47.4501 7.44959 47.5359 7.44899 47.6747C7.51613 48.9491 7.68895 50.2351 7.55113 51.5389C7.39003 53.261 7.12149 54.9929 6.67299 56.7093C6.12225 58.7658 6.10219 60.7204 6.18142 62.6339C6.85891 62.956 7.22941 62.4632 7.60105 62.0877C10.3564 59.4022 13.092 56.6972 15.8555 54.043C20.4585 49.609 24.4625 44.675 28.2442 39.5906C29.0217 38.5483 29.6164 37.3946 30.282 36.2879C31.6647 33.963 32.9767 31.5912 34.4518 29.3112C37.3537 24.8197 40.0066 20.2442 41.8351 15.3175C42.2731 14.1249 42.9206 12.9771 43.4097 11.8119C44.5614 9.04931 45.5762 6.26727 46.1718 3.39561C46.3507 2.5042 46.7509 1.6459 47.0454 0.775919Z" fill="white" />
                </svg>
            </section>,

    },
    {
        selector: ".job-postings",
        content:
            <section className="right-text step-5">
                <h6>Step 5</h6>
                <p>
                    Get a quick overview of your job listings to keep track the number of candidates reviewed and pending.
                </p>
                <svg className="arrow2" xmlns="http://www.w3.org/2000/svg" width="64" height="60" viewBox="0 0 64 60" fill="none">
                    <path d="M63.472 2.32734C62.8687 0.211214 62.7101 -0.0442877 61.1755 0.502403C59.04 1.26886 56.9242 2.15091 54.8654 3.21582C46.0778 7.77054 38.0777 13.8018 30.4858 20.5512C27.4869 23.2172 24.6336 26.1091 21.8142 29.0252C20.306 30.5853 18.9755 32.3741 17.6756 34.1441C14.4323 38.5838 11.3229 43.099 9.04069 48.1043C8.23156 49.8802 7.15981 51.5698 6.17505 53.281C5.9225 53.7297 5.63321 54.2535 5.02781 54.1453C4.96121 53.9759 4.80762 53.7849 4.85058 53.6532C5.34847 52.1216 5.84641 50.59 6.38779 49.0692C7.74463 45.3101 9.14321 41.5403 10.4775 37.7651C10.6877 37.1847 10.7833 36.5021 10.7168 35.9268C10.6621 35.502 10.3318 34.9613 9.98394 34.8749C9.63607 34.7885 8.97921 35.1104 8.68602 35.4488C8.20867 36.0208 7.86059 36.7462 7.57354 37.434C6.63608 39.7474 5.72122 42.0769 4.82724 44.4011C3.42977 48.0499 2.0758 51.7095 0.699223 55.3529C0.275401 56.4707 -0.169328 57.5938 0.372044 58.6508C2.69858 59.9086 5.14412 59.4247 7.71237 58.1964C9.78753 57.2041 11.905 56.3435 14.0061 55.4104C14.48 55.2012 14.9488 54.9276 15.3838 54.6297C16.8891 53.5749 18.4661 52.7541 20.1486 52.1914C21.2076 51.8324 22.2611 51.2663 23.2727 50.711C23.6918 50.483 24.0505 50.0291 24.4482 49.664C24.198 49.4648 24.0749 49.255 23.9383 49.279C22.6709 49.4282 21.3742 49.4751 20.1124 49.8313C18.4423 50.2811 16.7807 50.8384 15.1648 51.5705C13.2308 52.4608 11.3077 52.8108 9.40836 53.0561C8.97646 52.4427 9.39957 51.9943 9.70689 51.5645C11.8882 48.395 14.092 45.2417 16.2411 42.0695C19.8336 36.7834 24.0201 32.0032 28.3924 27.4169C29.2883 26.4743 30.3248 25.6933 31.3032 24.8503C33.361 23.0946 35.477 21.4007 37.475 19.5616C41.4116 15.9424 45.473 12.5545 50.0199 9.91982C51.1213 9.28665 52.1432 8.45449 53.209 7.77558C55.7373 6.17357 58.3078 4.70333 61.0376 3.63109C61.8859 3.30407 62.6643 2.76461 63.472 2.32734Z" fill="white" />
                </svg>
            </section>
    },
    {
        selector: ".job-postings",
        content:
            <section className="last-step right-text">
                <h6>Step 5</h6>
                <p>
                    Click 'View All Jobs' to manage all your active posts in one place.
                </p>
                <svg className="arrow1" xmlns="http://www.w3.org/2000/svg" width="64" height="60" viewBox="0 0 64 60" fill="none">
                    <path d="M0.520216 2.32734C1.12347 0.211214 1.28207 -0.0442877 2.81665 0.502403C4.95219 1.26886 7.068 2.15091 9.1268 3.21582C17.9144 7.77054 25.9145 13.8018 33.5064 20.5512C36.5053 23.2172 39.3586 26.1091 42.178 29.0252C43.6862 30.5853 45.0167 32.3741 46.3166 34.1441C49.5599 38.5838 52.6693 43.099 54.9515 48.1043C55.7606 49.8802 56.8324 51.5698 57.8171 53.281C58.0697 53.7297 58.359 54.2535 58.9644 54.1453C59.031 53.9759 59.1846 53.7849 59.1416 53.6532C58.6437 52.1216 58.1458 50.59 57.6044 49.0692C56.2476 45.3101 54.849 41.5403 53.5147 37.7651C53.3045 37.1847 53.2089 36.5021 53.2754 35.9268C53.3301 35.502 53.6604 34.9613 54.0082 34.8749C54.3561 34.7885 55.013 35.1104 55.3062 35.4488C55.7835 36.0208 56.1316 36.7462 56.4186 37.434C57.3561 39.7474 58.271 42.0769 59.1649 44.4011C60.5624 48.0499 61.9164 51.7095 63.293 55.3529C63.7168 56.4707 64.1615 57.5938 63.6201 58.6508C61.2936 59.9086 58.8481 59.4247 56.2798 58.1964C54.2047 57.2041 52.0872 56.3435 49.9861 55.4104C49.5122 55.2012 49.0433 54.9276 48.6084 54.6297C47.1031 53.5749 45.5261 52.7541 43.8436 52.1914C42.7846 51.8324 41.7311 51.2663 40.7195 50.711C40.3004 50.483 39.9417 50.0291 39.544 49.664C39.7942 49.4648 39.9172 49.255 40.0539 49.279C41.3213 49.4282 42.618 49.4751 43.8798 49.8313C45.5499 50.2811 47.2115 50.8384 48.8274 51.5705C50.7614 52.4608 52.6845 52.8108 54.5838 53.0561C55.0157 52.4427 54.5926 51.9943 54.2853 51.5645C52.104 48.395 49.9002 45.2417 47.7511 42.0695C44.1586 36.7834 39.9721 32.0032 35.5998 27.4169C34.7039 26.4743 33.6674 25.6933 32.689 24.8503C30.6312 23.0946 28.5152 21.4007 26.5172 19.5616C22.5806 15.9424 18.5192 12.5545 13.9723 9.91982C12.8709 9.28665 11.849 8.45449 10.7831 7.77558C8.25491 6.17357 5.68435 4.70333 2.95461 3.63109C2.10627 3.30407 1.32792 2.76461 0.520216 2.32734Z" fill="white" />
                </svg>
            </section>
    },
];


const styleDesktop = {
    maskArea: (base: any) => ({
        ...base,
        rx: "12px",
    }),
    popover: (base: any) => ({
        ...base,
        top: base.top - 30,
    }),
};

const DashboardWalkthrough = (Component: any) => {

    function ReactTour(props: any) {

        const nextHandler = ({
            currentStep,
            stepsLength,
            setIsOpen,
            setCurrentStep,
        }: any) => {
            const last = currentStep === stepsLength - 1;
            return (
                last ?
                    (
                        <span
                            className={`next-btn right-btn last-step-btn`}
                            onClick={() => {
                                setCurrentStep(0);
                                setIsOpen(false);
                            }}
                        >
                            Finish
                        </span>
                    ) : (
                        <span
                            className={`next-btn 
                                ${currentStep == 1 && 'right-btn'} 
                                ${currentStep == 2 && "step-3-btn"}
                                ${currentStep == 3 && "step-4-btn"}
                            ${currentStep == 4 && "very-right-btn"}
                            ${currentStep == 5 && "right-btn step-5-btn"}
                            `}
                            onClick={() => {
                                setCurrentStep(currentStep + 1);
                            }}
                        >
                            Next
                        </span>
                    )
            )
        }

        return (
            <div className='dashboard-walkthrough'>
                <TourProvider
                    steps={stepsDesktop}
                    scrollSmooth
                    styles={styleDesktop}
                    padding={{
                        mask: 0,
                    }}
                    nextButton={nextHandler}
                    position={'top'}
                    onClickMask={() => { }}
                >
                    <Component {...props} />
                </TourProvider>
            </div>
        )
    }

    return ReactTour;
}

export default DashboardWalkthrough;
