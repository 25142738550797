import getAccountInfoSlice from "./getAccountInfoSlice";
import userDetailsSlice from "./userDetailsSlice";
import userSigninSlice from "./userSigninSlice";
import userSignoutSlice from "./userSignoutSlice";
import editUserSlice from "./editUserSlice";

export const allUserSlices = {
  userDetails: userDetailsSlice,
  userSignIn: userSigninSlice,
  signOut: userSignoutSlice,
  getAccountInfoData: getAccountInfoSlice,
  editUser:editUserSlice,
};
