import { Autocomplete, Box, Chip, Divider, Radio, Switch } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import EcnInput from "../StyledComponents/V2/EcnInput";
import {
  jobRoles,
  skillsByRole,
  industry,
  allJobScoreParameters
} from "../../helpers/TempData";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getLocation } from "../../redux/slices/JobSlices/getLocationSlice";
import EcnButton from "../StyledComponents/EcnButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { jobCreate } from "../../redux/slices/JobSlices/jobCreateSlices";
import { enqueueSnackbar } from "notistack";
import Lottie from "react-lottie";
import FindingAJobGif from "../../assets/lottie/social_process_of_finding_applicants.json";
import {
  AiResumeEnabledAlertDialog,
  AiResumeDisabledAlertDialog
} from "./AiResumeAlertDialog";
import AiResumeScreeningPreferencesDialog from "./AiResumeScreeningPreferencesDialog";
import StarsIcon from "./StarsIcon";
import { KeyboardArrowLeft } from "@mui/icons-material";

const BasicDetails: React.FC<{
  jobData: any;
  setJobData: any;
  setStep: any;
  step: any;
  setJobDataChanged: any;
  setInitialSetupDone: any;
  showedAiDialog: {
    enabled: boolean;
    disabled: boolean;
  };
  setShowedAiDialog: React.Dispatch<
    React.SetStateAction<{
      enabled: boolean;
      disabled: boolean;
    }>
  >;
}> = ({
  jobData,
  setJobData,
  setStep,
  step,
  setJobDataChanged,
  setInitialSetupDone,
  showedAiDialog,
  setShowedAiDialog
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id") || "";
    const redirect = searchParams.get("redirect") || "";

    const { jobDescriptionData } = useAppSelector(
      (state: any) => state.jobDescription
    );

    const { getLocationData } = useAppSelector(
      (state: any) => state.locationList
    );

    const { jobData: successJobData, loading } = useAppSelector(
      (state: any) => state.jobCreateData
    );

    const [addressList, setAddressList] = useState<any>([]);
    const [click, setClick] = useState(false);
    const [selectedParameters, setSelectedParameters] = useState<string[]>([]);
    const [flipped, setFlipped] = useState(false);
    const [selectedChip, setSelectedChip] = useState<"default" | "custom">(
      "default"
    );
    const [open, setOpen] = useState({
      state: false,
      type: ""
    });

    const [
      openAiResumeScreeningEabledDialog,
      setOpenAiResumeScreeningEnabledDialog
    ] = useState(false);
    const [
      openAiResumeScreeningDisabledDialog,
      setOpenAiResumeScreeningDisabledDialog
    ] = useState(false);

    const [
      openAiResumeScreeningPreferencesDialog,
      setOpenAiResumeScreeningPreferencesDialog
    ] = useState(false);

    useEffect(() => {
      if (getLocationData) {
        setAddressList(getLocationData);
      }
    }, [getLocationData]);

    useEffect(() => {
      if (successJobData && click) {
        enqueueSnackbar("job draft successfully!", {
          variant: "success"
        });
        navigate("/jobs");
      }
    }, [successJobData]);

    const addressListHandler = (e: any) => {
      if (e !== null) dispatch(getLocation(e.target.value));
      if (jobDescriptionData) {
        setJobDataChanged(true);
      }
    };

    const addressHandler = (e: any, newValue: any) => {
      if (newValue) {
        setJobData({
          ...jobData,
          address: {
            state: newValue?.address?.countrySubdivision,
            country_code: newValue?.address?.countryCode,
            cities: [newValue?.address?.municipality],
            country: newValue?.address?.country
          }
        });
      } else {
        setJobData({
          ...jobData,
          address: {
            state: "",
            country_code: "",
            cities: [] as string[],
            country: ""
          }
        });
      }
      if (jobDescriptionData) {
        setJobDataChanged(true);
      }
    };

    const jobRoleHandler = (e: any, newValue: any) => {
      setInitialSetupDone(false);
      setJobData({
        ...jobData,
        job_roles: [newValue],
        skill_options: {
          ...jobData.skill_options,
          global_options:
            skillsByRole[newValue as keyof typeof skillsByRole] || []
        }
      });
      if (jobDescriptionData) {
        setJobDataChanged(true);
      }
    };

    const jobTitleHandler = (e: any) => {
      const inputValue = e.target.value;
      // Regular expression to allow numbers, alphabets, hyphens, and underscores
      const allowedCharactersRegex = /^[a-zA-Z0-9-_ ]*$/;


      // Check if the input value matches the allowed characters regex
      if (allowedCharactersRegex.test(inputValue) && inputValue?.length < 100) {
        setJobData({
          ...jobData,
          job_title: e.target.value
        });
      }

      if (jobDescriptionData) {
        setJobDataChanged(true);
      }
    };

    const jobTypeHandler = (newValue: string) => {
      switch (newValue) {
        case "Full Time":
          return 0;
        case "Part Time":
          return 2;
        case "Internship":
          return 1;
        case "Contractual":
          return 3;
        default:
          return 0;
      }
    };

    const roleTypeHandler = (e: any, newValue: any) => {
      setJobData((prev: any) => {
        return {
          ...prev,
          role_type: newValue,
          job_type: jobTypeHandler(newValue)
        };
      });
      if (jobDescriptionData) {
        setJobDataChanged(true);
      }
    };

    const industryHandler = (e: any, newValue: any) => {
      setJobData({
        ...jobData,
        industries: newValue
      });
      if (jobDescriptionData) {
        setJobDataChanged(true);
      }
    };

    const internalIdHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const allowedCharactersRegex = /^[a-zA-Z0-9-_]*$/;
      if (allowedCharactersRegex.test(inputValue) && inputValue?.length < 15) {
        setJobData({
          ...jobData,
          internal_job_id: inputValue
        });
        if (jobDescriptionData) {
          setJobDataChanged(true);
        }
      }
    };

    const navigateNextHandler = () => {
      if (step < 3) {
        setStep(step + 1);
        navigate(`?view=skill${id === "" ? "" : "&id=" + id}`);
      }
    };

    const draftHandler = () => {
      setClick(true);
      dispatch(
        jobCreate({
          ...jobData,
          draft_status: {
            is_draft: true,
            draft_state: 1
          }
        })
      );
    };

    const handleOpen = (type: string) => {
      setOpen({
        state: true,
        type: type
      });
    };

    const handleClose = () => {
      setOpen({
        state: false,
        type: ""
      });
    };

    const enableAiResumeScreening = () => {
      setJobData({
        ...jobData,
        ai_scoring_enabled: true
      });
    };

    const disableAiResumeScreening = () => {
      setJobData({
        ...jobData,
        ai_scoring_enabled: false
      });
    };

    const openAiResumeAlertDialog = () => {
      if (jobData.ai_scoring_enabled) {
        if (showedAiDialog.enabled) {
          navigateNextHandler();
          return;
        }
        setOpenAiResumeScreeningEnabledDialog(true);
        setShowedAiDialog({ ...showedAiDialog, enabled: true });
      } else {
        if (showedAiDialog.disabled) {
          navigateNextHandler();
          return;
        }
        setOpenAiResumeScreeningDisabledDialog(true);
        setShowedAiDialog({ ...showedAiDialog, disabled: true });
      }
    };

    const closeAiResumeAlertDialog = () => {
      setOpenAiResumeScreeningEnabledDialog(false);
      setOpenAiResumeScreeningDisabledDialog(false);
    };

    const customParamChangeHandler = (selectedParameters: any) => {
      setJobData({
        ...jobData,
        custom_scoring_attributes: selectedParameters
      });
      setFlipped(false);
    };

    return (
      <div className="basic-details">
        <div className="body-wrapper">
          <div className="body">
            <div className="row">
              <p className="label">Job title*</p>
              <EcnInput
                placeholder="Enter job title"
                className="input-box"
                onChange={jobTitleHandler}
                value={jobData?.job_title}
              />
            </div>

            <div className="row">
              <p className="label">Job role*</p>
              <Autocomplete
                autoSelect
                value={jobData.job_roles}
                options={jobRoles}
                forcePopupIcon={false}
                disableClearable
                onOpen={() => handleOpen("roles")}
                onClose={handleClose}
                getOptionLabel={(university: any) => university}
                onChange={(e, newValue) => jobRoleHandler(e, newValue)}
                renderInput={(params) => (
                  <Box sx={{ position: "relative" }}>
                    <EcnInput
                      {...params}
                      placeholder="Enter job role"
                      className="auto-box"
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "57%",
                        right: "13px", // Adjust this value as needed to position the arrow icon
                        transform: "translateY(-50%) rotate(" + "0deg" + ")",
                        transition: "transform 0.3s ease", // Add transition for smooth rotation
                        pointerEvents: "none"
                      }}
                    >
                      {open.state && open.type === "roles" ? (
                        <Icon
                          icon="lucide:chevron-up"
                          width={24}
                          height={24}
                          color="rgba(33, 33, 33, 0.8)"
                        />
                      ) : (
                        <Icon
                          icon="lucide:chevron-down"
                          width={24}
                          height={24}
                          color="rgba(33, 33, 33, 0.8)"
                        />
                      )}
                    </Box>
                  </Box>
                )}
                renderOption={(props, option, { selected }) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{
                      margin: "0px 10px",
                      marginBottom: "0px !important",
                      borderRadius: "8px !important",
                      padding: "10px 15px !important",
                      bgcolor: selected
                        ? "rgba(0, 52, 187, 0.05) !important"
                        : "transparent", // Apply the "info" color for selected options
                      "&:hover": {
                        bgcolor: "rgba(0, 52, 187, 0.05) !important", // Lighten the color on hover
                        color: "rgba(0, 52, 187, 1)"
                      },
                      color: jobData?.job_roles?.includes(option)
                        ? "rgba(0, 52, 187, 1)"
                        : ""
                    }}
                  >
                    {option}
                  </Box>
                )}
              />
            </div>

            <div className="row">
              <p className="label">Role type*</p>
              <Autocomplete
                autoSelect
                autoHighlight
                forcePopupIcon={false}
                disableClearable
                onOpen={() => handleOpen("role_type")}
                onClose={handleClose}
                value={jobData?.role_type}
                options={[
                  "Full Time",
                  "Part Time",
                  "Internship",
                  "Contractual"
                ]}
                getOptionDisabled={(option) => {
                  if (
                    ["Analyst", "Associate", "Other"].some((role) =>
                      jobData?.job_roles.includes(role)
                    )
                  ) {
                    return false; // "Internship" and "Contractual" remain active
                  }
                  return (
                    option === "Internship" ||
                    option === "Contractual"
                  );
                }}
                getOptionLabel={(university: any) => university}
                onChange={(e, newValue) => roleTypeHandler(e, newValue)}
                noOptionsText="No options found" // Fallback message when no options are available
                renderInput={(params) => (
                  <Box sx={{ position: "relative" }}>
                    <EcnInput
                      {...params}
                      placeholder="Enter job role"
                      className="auto-box"
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "57%",
                        right: "13px", // Adjust this value as needed to position the arrow icon
                        transform: "translateY(-50%) rotate(" + "0deg" + ")",
                        transition: "transform 0.3s ease", // Add transition for smooth rotation
                        pointerEvents: "none"
                      }}
                    >
                      {open.state && open.type === "role_type" ? (
                        <Icon
                          icon="lucide:chevron-up"
                          width={24}
                          height={24}
                          color="rgba(33, 33, 33, 0.8)"
                        />
                      ) : (
                        <Icon
                          icon="lucide:chevron-down"
                          width={24}
                          height={24}
                          color="rgba(33, 33, 33, 0.8)"
                        />
                      )}
                    </Box>
                  </Box>
                )}
                renderOption={(props, option, { selected }) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{
                      margin: "0px 10px",
                      marginBottom: "0px !important",
                      borderRadius: "8px !important",
                      padding: "10px 15px !important",
                      bgcolor: selected
                        ? "rgba(0, 52, 187, 0.05) !important"
                        : "transparent", // Apply the "info" color for selected options
                      "&:hover": {
                        bgcolor: "rgba(0, 52, 187, 0.05) !important", // Lighten the color on hover
                        color: "rgba(0, 52, 187, 1)"
                      },
                      color: jobData?.role_type?.includes(option)
                        ? "rgba(0, 52, 187, 1)"
                        : ""
                    }}
                  >
                    {option}
                  </Box>
                )}
              />
            </div>

            <div className="row">
              <p className="label">Industry*</p>
              <Autocomplete
                multiple
                forcePopupIcon={false}
                disableClearable
                onOpen={() => handleOpen("industry")}
                onClose={handleClose}
                value={jobData.industries}
                options={industry}
                getOptionLabel={(university: any) => university}
                onChange={(e, newValue) => industryHandler(e, newValue)}
                renderInput={(params) => (
                  <Box sx={{ position: "relative" }}>
                    <EcnInput
                      {...params}
                      placeholder="Enter job role"
                      className="auto-box"
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "57%",
                        right: "13px", // Adjust this value as needed to position the arrow icon
                        transform: "translateY(-50%) rotate(" + "0deg" + ")",
                        transition: "transform 0.3s ease", // Add transition for smooth rotation
                        pointerEvents: "none"
                      }}
                    >
                      {open.state && open.type === "industry" ? (
                        <Icon
                          icon="lucide:chevron-up"
                          width={24}
                          height={24}
                          color="rgba(33, 33, 33, 0.8)"
                        />
                      ) : (
                        <Icon
                          icon="lucide:chevron-down"
                          width={24}
                          height={24}
                          color="rgba(33, 33, 33, 0.8)"
                        />
                      )}
                    </Box>
                  </Box>
                )}
                renderOption={(props, option, { selected }) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{
                      margin: "0px 10px",
                      marginBottom: "4px !important",
                      borderRadius: "8px !important",
                      padding: "10px 15px !important",
                      bgcolor: selected
                        ? "rgba(0, 52, 187, 0.05) !important"
                        : "transparent", // Apply the "info" color for selected options
                      "&:hover": {
                        bgcolor: "rgba(0, 52, 187, 0.05) !important", // Lighten the color on hover
                        color: "rgba(0, 52, 187, 1)"
                      },
                      color: jobData?.industries?.includes(option)
                        ? "rgba(0, 52, 187, 1)"
                        : ""
                    }}
                  >
                    {option}
                  </Box>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      sx={{
                        bgcolor: "rgba(0, 52, 187, 0.8)",
                        color: "#fff",
                        marginLeft: "0 !important",
                        marginRight: "6px !important",
                        "& .MuiChip-deleteIcon": {
                          color: "#fff",

                          "&:hover": {
                            color: "rgba(255, 255, 255, 0.8)"
                          }
                        }
                      }}
                    />
                  ))
                }
              />
            </div>

            <div className="row">
              <p className="label">Location*</p>
              <Autocomplete
                forcePopupIcon={false}
                disableClearable
                onOpen={() => handleOpen("location")}
                onClose={handleClose}
                options={[
                  ...(customLocations[0]?.results ?? []),
                  ...(addressList?.results ?? [])
                ]}
                value={`${jobData?.address?.cities?.join(", ") ?? ""}, ${jobData?.address?.state ?? ""
                  }, ${jobData?.address?.country ?? ""}`}
                getOptionLabel={(option: any) =>
                  option?.address
                    ? `${option.address.municipality}, ${option.address.countrySubdivision}, ${option.address.country}`
                    : jobData?.address?.cities?.length > 0
                      ? `${jobData?.address?.cities[0]}, ${jobData?.address?.state}, ${jobData?.address?.country}`
                      : ""
                }
                onInputChange={(e) => addressListHandler(e)}
                onChange={(e, newValue) => addressHandler(e, newValue)}
                renderInput={(params) => (
                  <Box sx={{ position: "relative" }}>
                    <EcnInput
                      {...params}
                      placeholder="Enter job role"
                      className="auto-box"
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "57%",
                        right: "13px", // Adjust this value as needed to position the arrow icon
                        transform: "translateY(-50%) rotate(" + "0deg" + ")",
                        transition: "transform 0.3s ease", // Add transition for smooth rotation
                        pointerEvents: "none"
                      }}
                    >
                      {open.state && open.type === "location" ? (
                        <Icon
                          icon="lucide:chevron-up"
                          width={24}
                          height={24}
                          color="rgba(33, 33, 33, 0.8)"
                        />
                      ) : (
                        <Icon
                          icon="lucide:chevron-down"
                          width={24}
                          height={24}
                          color="rgba(33, 33, 33, 0.8)"
                        />
                      )}
                    </Box>
                  </Box>
                )}
                renderOption={(props, option, { selected }) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{
                      margin: "0px 10px",
                      marginBottom: "0px !important",
                      borderRadius: "8px !important",
                      padding: "10px 15px !important",
                      bgcolor: selected
                        ? "rgba(0, 52, 187, 0.05) !important"
                        : "transparent", // Apply the "info" color for selected options
                      "&:hover": {
                        bgcolor: "rgba(0, 52, 187, 0.05) !important", // Lighten the color on hover
                        color: "rgba(0, 52, 187, 1)"
                      },
                      color:
                        jobData?.address?.cities?.includes(
                          option.address.municipality
                        ) &&
                          jobData?.address?.state ===
                          option.address.countrySubdivision &&
                          jobData?.address?.country === option.address.country
                          ? "rgba(0, 52, 187, 1)"
                          : ""
                    }}
                  >
                    {`${option.address.municipality},
                ${option.address.countrySubdivision} ${option.address.countrySubdivision === "" ? "" : ","
                      } ${option.address.country}`}
                  </Box>
                )}
              />
            </div>

            <div className="row">
              <p className="label">Internal Job ID</p>
              <EcnInput
                placeholder="Enter internal job ID"
                className="input-box"
                onChange={internalIdHandler}
                value={jobData?.internal_job_id}
              />
            </div>
          </div>
          <div className="ai-resume-screening-card">
            <div className={`card-inner ${flipped ? "flipped" : ""}`}>
              <div className="card-front">
                <div className="card-header">
                  <div className="header-text">
                    <div className="reduce">REDUCE</div>
                    <div className="number">
                      <span>90</span>
                      <span className="small">%</span>
                    </div>
                    <div className="time-effort">{"TIME & EFFORT"}</div>
                  </div>
                  <div className="new-chip">
                    <StarsIcon />
                    <div>New</div>
                  </div>
                  <div className="search-gif">
                    <Lottie
                      width={208}
                      height={208}
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: FindingAJobGif
                      }}
                    />
                  </div>
                </div>
                <div className="card-body">
                  <div className="title">Enable AI-Resume Screening</div>
                  <div className="text">
                    As soon as a candidate applies for the job, our AI will
                    immediately review them on your behalf.
                  </div>
                  <div className="credits">
                    <div className="number">10</div>
                    <div className="line"></div>
                    <div className="description">
                      <div className="credits">Credits</div>
                      <div className="candidate">per Candidate</div>
                    </div>
                  </div>
                </div>
                <div className="card-buttons">
                  <div>Enable Features</div>
                  <Switch
                    checked={jobData.ai_scoring_enabled}
                    onChange={() => {
                      if (jobData.ai_scoring_enabled) {
                        setShowedAiDialog({ ...showedAiDialog, disabled: false });
                        disableAiResumeScreening();
                      } else {
                        setShowedAiDialog({ ...showedAiDialog, enabled: false });
                        enableAiResumeScreening();
                      }
                    }}
                  />
                </div>

                <Divider className="divider" />

                <div className="params-selector">
                  <p className="heading">Set Parameters</p>
                  <Chip
                    className={`chip ${selectedChip === "default" ? "selected" : ""
                      }`}
                    onClick={() => {
                      setSelectedChip("default");
                      setSelectedParameters([]);
                    }}
                    label="Onefinnet’s Default"
                  />
                  <Chip
                    className={`chip ${selectedChip === "custom" ? "selected" : ""
                      }`}
                    onClick={() => {
                      setSelectedChip("custom");
                      setFlipped(!flipped);
                    }}
                    label="Custom"
                  />
                </div>
              </div>

              <div className="card-back">
                <div className="job-parameter-header">
                  <h2 className="title"> <KeyboardArrowLeft onClick={() => {
                    setSelectedChip("default");
                    setFlipped(!flipped);
                  }} style={{ cursor: "pointer" }} /> Parameters</h2>
                  <p className="sub-title">
                    Set Parameters for AI to give you results based on your
                    preferences
                  </p>
                </div>
                <div className="separator" />
                <div className="options">
                  <div
                    onClick={() => {
                      setFlipped(!flipped);
                    }}
                    className="option-container"
                  >
                    <Radio
                      checked={selectedChip === "default"}
                      onChange={() => {
                        setSelectedChip("default");
                        setFlipped(!flipped);
                      }}
                      value="default"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "Set Default" }}
                      style={{
                        marginLeft: -10
                      }}
                    />
                    <div className="option-text">
                      <div className="option-text-heading">Set Default</div>
                      {/*<div className="option-text-description">
                      We will set the default parameters for the screening based
                      on your job description
                    </div>*/}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedChip("custom");
                    }}
                    className="option-container"
                  >
                    <Radio
                      checked={selectedChip === "custom"}
                      value="custom"
                      name="radio-buttons"
                      inputProps={{
                        "aria-label": "Select your own preferences"
                      }}
                      style={{
                        marginLeft: -10
                      }}
                    />
                    <div className="option-text">
                      <div className="option-text-heading">
                        Select your own preferences
                      </div>
                      {/*
                    <div className="option-text-description">
                      You can select amongst the few presets
                    </div>*/}
                    </div>
                  </div>
                </div>
                <div
                  className="parameters-section"
                  data-visible={selectedChip == "custom"}
                >
                  <p className="title">Parameters*</p>
                  <div className="parameters-list">
                    {allJobScoreParameters.map((parameter) => {
                      return (
                        <div
                          className="parameter"
                          data-selected={selectedParameters.includes(parameter)}
                          onClick={() => {
                            if (selectedParameters.includes(parameter)) {
                              setSelectedParameters((prev) => {
                                return prev.filter((v) => v !== parameter);
                              });
                            } else {
                              setSelectedParameters((prev) => {
                                return [...prev, parameter];
                              });
                            }
                          }}
                        >
                          {parameter}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="cta-wrapper">
                  <EcnButton
                    onClick={() => customParamChangeHandler(selectedParameters)}
                    className="confirm-button"
                    variant="contained"
                  >
                    Confirm
                  </EcnButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-button">
          <div className="bottom-container">
            <div className="left">
              {id != "" && (
                <EcnButton className="back" onClick={() => navigate("/jobs")}>
                  <Icon icon="lucide:arrow-left" /> Back
                </EcnButton>
              )}

              <EcnButton
                className="save"
                onClick={draftHandler}
                disabled={loading}
              >
                <Icon icon="lucide:bookmark" /> Save draft
              </EcnButton>
            </div>
            {redirect === "" && (
              <EcnButton
                className="next"
                variant="contained"
                onClick={() => {
                  openAiResumeAlertDialog();
                }}
                disabled={
                  jobData?.job_title === "" ||
                  jobData?.job_roles?.length === 0 ||
                  jobData?.role_type === "" ||
                  jobData?.industries.length === 0 ||
                  jobData?.address?.cities.length === 0
                }
              >
                <Icon
                  icon="lucide:arrow-right"
                  className="icon"
                  width={18}
                  height={18}
                />{" "}
                Next
              </EcnButton>
            )}

            {redirect != "" && (
              <EcnButton
                className="next"
                variant="contained"
                onClick={() => navigate(`?view=${redirect}`)}
                style={{ width: "180px" }}
              >
                Save Changes
              </EcnButton>
            )}
          </div>
        </div>
        <AiResumeEnabledAlertDialog
          open={openAiResumeScreeningEabledDialog}
          onContinue={() => {
            enableAiResumeScreening();
            closeAiResumeAlertDialog();
            setShowedAiDialog({ ...showedAiDialog, enabled: true });
            if (jobData.custom_scoring_attributes?.length > 0) {
              navigateNextHandler();
            } else {
              setOpenAiResumeScreeningPreferencesDialog(true);
            }
          }}
          onCancel={() => {
            disableAiResumeScreening();
            closeAiResumeAlertDialog();
            navigateNextHandler();
          }}
        />
        <AiResumeDisabledAlertDialog
          open={openAiResumeScreeningDisabledDialog}
          yesHandler={() => {
            //Question:- Do you wish to enable resume screening
            enableAiResumeScreening();
            closeAiResumeAlertDialog();
            if (jobData.custom_scoring_attributes?.length > 0) {
              navigateNextHandler();
            } else {
              setOpenAiResumeScreeningPreferencesDialog(true);
            }
          }}
          noHandler={() => {
            disableAiResumeScreening();
            closeAiResumeAlertDialog();
            navigateNextHandler();
          }}
        />
        <AiResumeScreeningPreferencesDialog
          open={openAiResumeScreeningPreferencesDialog}
          onConfirm={(preferenceOption, selectedParameters) => {
            customParamChangeHandler(selectedParameters);
            navigateNextHandler();
            setOpenAiResumeScreeningPreferencesDialog(false);
          }}
        />
      </div>
    );
  };

export default BasicDetails;
const customLocations: any = [
  {
    results: [
      {
        type: "Geography",
        id: "custom1",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "New York",
          countrySubdivision: "NY",
          country: "USA"
        }
      },
      {
        type: "Geography",
        id: "custom2",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "Chicago",
          countrySubdivision: "IL",
          country: "USA"
        }
      },
      {
        type: "Geography",
        id: "custom3",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "San Francisco",
          countrySubdivision: "CA",
          country: "USA"
        }
      },
      {
        type: "Geography",
        id: "custom4",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "London",
          countrySubdivision: "",
          country: "UK"
        }
      },
      {
        type: "Geography",
        id: "custom5",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "Hong Kong",
          countrySubdivision: "",
          country: "China"
        }
      }
    ]
  }
];
