import jobListSlice from "./jobListSlice";
import candidatesListSlice from "./candidatesListSlice";
import candidatesDetails from "./candidatesDetialsSlice";
import updateJobStatus from "./updateJobStatusSlice";
import scoreParamList from "./scoreParamListSlice";
import scoreUpdate from "./scoreUpdateSlice";
import enableScore from "./enableScoreSlice";
import scoreParamUpdate from "./scoreParamUpdateSlice";
import aiGenerateAvgRelevancyScoreSlice from "./aiGenerateAvgRelevancyScoreSlice";
import regenerateAvgRelevancyScoreSlice from "./aiRegenerateAvgRelevancyScoreSlice";
import jobListPaginationSlice from "./jobListPaginationSlice";
import aiCvRelevancyFeedbackSlice from "./aiCvRelevancyFeedbackSlice";
import talentScoreHomeSlice from "./talentScoreHomeSlice";
import scoringNotesSlice from "./scoringNotesSlice";

export const allCandidatesSlices = {
  jobsList: jobListSlice,
  jobsListPagination: jobListPaginationSlice,
  candidateLists: candidatesListSlice,
  candidateDetails: candidatesDetails,
  jobStatus: updateJobStatus,
  scoreParamList: scoreParamList,
  putScoreUpdate: scoreUpdate,
  enableScoreData: enableScore,
  putScoreParamUpdate: scoreParamUpdate,
  relevancyScore: aiGenerateAvgRelevancyScoreSlice,
  regenRelevancyScore: regenerateAvgRelevancyScoreSlice,
  relevancyFeedback: aiCvRelevancyFeedbackSlice,
  talentScoreHome: talentScoreHomeSlice,
  scoringNotes: scoringNotesSlice
};
