import "./App.css";

import * as Sentry from "@sentry/react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { getAccountInfo } from "./redux/slices/userSlices/getAccountInfoSlice";

import SignIn from "./pages/SignIn";

import InviteUser from "./pages/InviteUsers";
import Error from "./pages/404";

import Promotions from "./pages/Promotions";
import CandidateDetails from "./pages/CandidateDetails";

import Analytics from "./pages/Analytics";
import {
  MaterialDesignContent,
  SnackbarProvider,
  closeSnackbar
} from "notistack";
import { CircularProgress, styled } from "@mui/material";
import { Icon } from "@iconify/react";

import { useEffect } from "react";
import { userDetails } from "./redux/slices/userSlices/userDetailsSlice";
import { Unauthorize } from "./pages/Unauthorize";
import Mails from "./pages/Mails";
import Template from "./pages/Template";
import Notifications from "./pages/Notifications";
import Events from "./pages/Events";

import NoAccess from "./pages/NoAccess";
import JobCreations from "./pages/JobCreations";
import Dashboard from "./pages/Dashboard";
import JobsLists from "./pages/JobsLists";
import JobPromotion from "./pages/JobPromotion";
import Permission from "./pages/Permission";
import Search from "./pages/Search";
import CompanyDetail from "./pages/CompanyDetail";
import EventDetail from "./pages/EventDetail";
import CreateCertificate from "./pages/CreateCertificate";
import NewRegistrant from "./pages/NewRegistrant";
import TrainingMaterials from "./pages/TrainingMaterials";
import AddTrainingMaterials from "./pages/AddTrainingMaterials";

const SIGNIN_REDIRECT = process.env.REACT_APP_AUTH_REDIRECT;

const ProtectedRoute = ({
  user,
  next,
  children
}: {
  user: any;
  next: string;
  children: any;
}) => {
  if (user) {
    return children;
  }

  // return (window.location.href = `${SIGNIN_REDIRECT}?origin=${window?.location.href}`);
};

Sentry.init({
  dsn: "https://64c9bab30d464717e2b4c0f268e51e1a@o4505782618226688.ingest.sentry.io/4506154920574976",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["https://beta.recruit.test.ecndev.io"]
    }),
    new Sentry.Replay()
  ],
  ignoreErrors: [],
  tracesSampleRate: 0.05,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px"
    },
    svg: {
      color: "#2BBA1D",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px"
    }
  },

  "&.notistack-MuiContent-error": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px"
    },
    svg: {
      color: "#BA0000",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px"
    }
  },
  "&.notistack-MuiContent-warning": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    padding: "20px 23px 16px 21px",
    minWidth: "442px",
    "&.notistack-snackbar": {
      gap: "13px"
    },
    svg: {
      color: "rgba(254, 152, 0, 1)",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px"
    }
  },
  "&.notistack-MuiContent-info": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px"
    },
    svg: {
      color: "rgba(33, 33, 33, 1)",
      transform: "rotate(180deg)",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px"
    }
  },
  "&.notistack-MuiContent-default": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    minWidth: "442px",
    fontWeight: "500",
    padding: "12px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px"
    }
  }
}));

function App() {
  const dispatch = useAppDispatch();
  const { userInfo, loading, errorData } = useAppSelector(
    (state: any) => state.userDetails
  );

  const { accountInfos } = useAppSelector((state) => state.getAccountInfoData);

  useEffect(() => {
    dispatch(userDetails());
  }, []);

  useEffect(() => {
    dispatch(getAccountInfo());

  }, []);

  useEffect(() => {
    // Check if the page has already been reloaded
    if (!sessionStorage.getItem("reloaded")) {
      sessionStorage.setItem("reloaded", "true"); // Mark as reloaded
      window.location.reload(); // Reload the page
    }
  }, []);

  if (
    errorData?.message === "401" &&
    !accountInfos &&
    window.location.pathname !== "/signin"
  ) {
    window.location.href = `${SIGNIN_REDIRECT}?origin=${window?.location.href}`;
  }
  if (
    errorData?.message === "401" &&
    accountInfos &&
    window?.location.pathname !== "/signup/details"
  ) {
    return (
      <Router>
        <NoAccess />{" "}
      </Router>
    );
  }

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Router>
      <SnackbarProvider
        maxSnack={4}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
          warning: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
          default: StyledMaterialDesignContent
        }}
        iconVariant={{
          success: <Icon icon="lucide:check-circle-2" />,
          error: <Icon icon="lucide:x-circle" />,
          warning: <Icon icon="lucide:alert-circle" />,
          info: <Icon icon="lucide:alert-circle" />
        }}
        action={(snackbarId: any) => (
          <Icon
            onClick={() => closeSnackbar(snackbarId)}
            icon="basil:cross-solid"
            className="snackbar-cross"
          />
        )}
      >
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />

          {process.env.REACT_APP_DEPLOYMENT_ENV === "dev" && (
            <Route path="/signin" element={<SignIn />} />
          )}

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute user={userInfo} next="/dashboard">
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/company-details"
            element={
              <ProtectedRoute user={userInfo} next="/company-details">
                <CompanyDetail />
              </ProtectedRoute>
            }
          />

          <Route
            path="/jobs"
            element={
              <ProtectedRoute user={userInfo} next="/jobs">
                <JobsLists />
              </ProtectedRoute>
            }
          />

          <Route
            path="/job/promotion/:jobId"
            element={
              <ProtectedRoute user={userInfo} next="/job/promotion/:jobId">
                <JobPromotion />
              </ProtectedRoute>
            }
          />

          <Route
            path="/events"
            element={
              <ProtectedRoute user={userInfo} next="/events">
                <Events />
              </ProtectedRoute>
            }
          />

          <Route
            path="/event/:eventId"
            element={
              <ProtectedRoute user={userInfo} next="/events">
                <EventDetail />
              </ProtectedRoute>
            }
          />

          <Route
            path="/event/:eventId/create-certificate"
            element={
              <ProtectedRoute user={userInfo} next="/events">
                <CreateCertificate />
              </ProtectedRoute>
            }
          />

          <Route
            path="/event/:eventId/new-registrant"
            element={
              <ProtectedRoute user={userInfo} next="/events">
                <NewRegistrant />
              </ProtectedRoute>
            }
          />

          <Route
            path="/event/:eventId/training-materials"
            element={
              <ProtectedRoute user={userInfo} next="/events">
                <TrainingMaterials />
              </ProtectedRoute>
            }
          />

          <Route
            path="/event/:eventId/training-materials/add"
            element={
              <ProtectedRoute user={userInfo} next="/events">
                <AddTrainingMaterials />
              </ProtectedRoute>
            }
          />

          {/* To be deleted after ishaan rebuilds this */}
          <Route
            path="/invite-user"
            element={
              <ProtectedRoute user={userInfo} next="/invite-user">
                {" "}
                <InviteUser />
              </ProtectedRoute>
            }
          />

          {/* TO be deleted after we finalise this promotions thing */}
          <Route
            path="/job/promotions"
            element={
              <ProtectedRoute user={userInfo} next="/job/promotions">
                {" "}
                <Promotions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/analytics"
            element={
              <ProtectedRoute user={userInfo} next="analytics">
                {" "}
                <Analytics />
              </ProtectedRoute>
            }
          />

          <Route
            path="/no-access"
            element={
              <ProtectedRoute user={userInfo} next="no-access">
                {" "}
                <Unauthorize />
              </ProtectedRoute>
            }
          />

          <Route
            path="/mails"
            element={
              <ProtectedRoute user={userInfo} next="/mails">
                <Mails />
              </ProtectedRoute>
            }
          />

          <Route
            path="/mails/template"
            element={
              <ProtectedRoute user={userInfo} next="/mails/template">
                <Template />
              </ProtectedRoute>
            }
          />

          <Route
            path="/candidates/details"
            element={
              <ProtectedRoute user={userInfo} next="/candidates/details">
                {" "}
                <CandidateDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path="/notifications"
            element={
              <ProtectedRoute user={userInfo} next="/notifications">
                <Notifications />
              </ProtectedRoute>
            }
          />

          <Route
            path="/job-creations"
            element={
              <ProtectedRoute user={userInfo} next="/job-creations">
                <JobCreations />
              </ProtectedRoute>
            }
          />

          <Route
            path="/permission"
            element={
              <ProtectedRoute user={userInfo} next="/permission">
                <Permission />
              </ProtectedRoute>
            }
          />

          <Route
            path="/search"
            element={
              <ProtectedRoute user={userInfo} next="/search">
                <Search />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<Error />} />
        </Routes>
      </SnackbarProvider>
    </Router>
  );
}

export default App;
