import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import { useNavigate } from "react-router-dom";
import EcnButton from "../StyledComponents/EcnButton";

const StyledTableCell = styled(TableCell)(({}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8F8F8",
    color: "#212121",
    borderRight: "1px solid rgba(0,0,0,0.2)",
    width: "200px",
    fontSize: 14,
    fontWeight: "500",
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "left",
    fontWeight: "400",
    color: "#212121",
    width: "200px",
  },
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const MapingTable: React.FC<{
  data: any;
  setUserData: any;
  setActive: any;
  setMapData: any;
  mapData: any;
  setSelectedOption: any;
  selectedOption: any;
  setCheckField: any;
  checkFiled: any;
}> = ({
  data,
  setUserData,
  setActive,
  setMapData,
  mapData,
  setSelectedOption,
  selectedOption,
  setCheckField,
  checkFiled,
}) => {
  const navigate = useNavigate();

  const data2: any = [];
  const headers: any = data?.length > 0 ? Object.keys(data[0]) : [];

  const [total, setTotal] = useState<any>();
  const [requireField, setRequiredField] = useState<any>();

  const [error, setError] = useState<any>(false);

  const [mapField, setMapField] = useState<any>([]);

  useEffect(() => {
    if (data) {
      var filtered = ["Email", "First Name", "Last Name"].filter(function (e) {
        return Object?.keys(data[0])?.indexOf(e) < 0;
      });

      setRequiredField(filtered);
      setTotal(Object?.keys(data[0])?.length);
    }
  }, []);

  useEffect(() => {
    if (mapData) {
      data?.map((item: any, index: any) => {
        pushToAry(item, index);
      });
    }
  }, [mapData]);

  function pushToAry(item: any, val: any) {
    var obj: any = {};
    let key: any = Object.keys(item);
    key?.map((keys: any) => {
      let a = mapData?.[keys];
      if (mapData?.[keys] === "first_name") {
        obj["first_name"] = item?.[keys];
        data2?.splice(1, 1, obj);
      }
      if (mapData?.[keys] === "last_name") {
        obj["last_name"] = item?.[keys];

        data2?.splice(1, 1, obj);
      }
      if (mapData?.[keys] === "email") {
        obj["email"] = item?.[keys];

        data2?.splice(1, 1, obj);
      }
      if (mapData?.[keys] === "designation") {
        obj["designation"] = item?.[keys];

        data2?.splice(1, 1, obj);
      }
      setUserData(data2);
    });
  }

  const selectHandler = (e: any, value: any, number: any) => {
    setError(false);
    let temp: any = [...mapField];
    setMapField(temp);

    setMapData({
      ...mapData,
      [value]: e.target.value,
    });
    if (selectedOption?.includes(e.target.value)) {
      var filteredArray = selectedOption.filter(
        (e: any) => e !== e.target.value
      );
      setSelectedOption(filteredArray);
    } else {
      selectedOption.splice(number, 1, e.target.value);
    }
  };

  const nextHandler = () => {
    if (
      selectedOption?.includes("email") &&
      selectedOption?.includes("first_name") &&
      selectedOption?.includes("last_name")
    ) {
      setActive((next: any) => next + 1);
      navigate("?view=permission&with=mapped");
    } else {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
      setError(true);
    }
  };

  const removeValue = (item: any) => {
    var filteredArray = selectedOption.filter((e: any) => e != mapData[item]);
    setSelectedOption(filteredArray);
  };

  const crossHandler = (value: any) => {
    setMapData({
      ...mapData,
      [value]: "",
    });
    removeValue(value);
  };

  return (
    <div className="maping-table">
      <div className="container">
        <h3 className="title">Mapping Details</h3>
        <p className="req">Required fields *</p>
        <h4 className="field">Email,First Name, Last Name</h4>
        {requireField?.length != 0 && (
          <div className="error">
            <p className="missing">
              {" "}
              {requireField?.map((item: any, index: any) => (
                <>
                  <span>
                    {item}
                    {index != requireField?.length - 1 && ","}
                  </span>
                </>
              ))}
              field is missing
            </p>
            <p
              className="replace"
              onClick={() => navigate("?view=user-detail")}
            >
              reupload the file?
            </p>
          </div>
        )}
        <h4 className="detected">{total} contacts detected</h4>

        <div>
          <TableContainer component={Paper} variant="outlined">
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Column Name</StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: 200 }}>
                    Preview Information
                  </StyledTableCell>
                  <StyledTableCell align="center">Mapped</StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ borderRight: "none" }}
                  >
                    Dashboard Property
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {headers?.map((item: any, index: any) => (
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="left"
                      style={{ borderRight: "1px solid rgba(0,0,0,0.2)" }}
                    >
                      {item}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{
                        borderRight: "1px solid rgba(0,0,0,0.2)",
                        width: 100,
                      }}
                    >
                      {data?.map((value: any) => (
                        <p style={{ maxWidth: "300px", overflow: "scroll" }}>
                          {value?.[item]}
                        </p>
                      ))}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{
                        borderRight: "1px solid rgba(0,0,0,0.2)",
                        color: "rgba(33, 33, 33, 0.5);",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ color: "rgba(33, 33, 33, 0.5)" }}>
                        {mapData?.[item] === undefined ||
                        mapData?.[item] === "" ? (
                          "unmapped"
                        ) : (
                          <Icon
                            icon="mdi:tick-circle"
                            style={{ color: "#229651", fontSize: "35px" }}
                          />
                        )}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{ display: "flex", width: "100%" }}
                    >
                      <FormControl fullWidth>
                        <Select
                          id="demo-simple-select"
                          size="small"
                          value={
                            mapData != undefined
                              ? mapData[item] === ""
                                ? 0
                                : mapData[item] === undefined
                                ? 0
                                : mapData[item]
                              : 0
                          }
                          onChange={(e) => selectHandler(e, item, index)}
                        >
                          <MenuItem value={0} disabled>
                            Select Dashboard Property
                          </MenuItem>
                          {option.map((items: any, index: any) => (
                            <MenuItem
                              value={items.field}
                              disabled={selectedOption?.includes(items.field)}
                            >
                              {items?.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {selectedOption.length != 0 &&
                          error &&
                          !selectedOption?.includes(mapData[item]) && (
                            <p style={{ color: "#ba0000" }}>
                              Field are not mapped
                            </p>
                          )}
                        {selectedOption.length === 0 && error && (
                          <p style={{ color: "#ba0000" }}>
                            Field are not mapped
                          </p>
                        )}
                      </FormControl>

                      {selectedOption?.length != 0 &&
                        selectedOption?.includes(mapData[item]) && (
                          <Icon
                            icon="basil:cross-outline"
                            width={40}
                            height={40}
                            onClick={() => crossHandler(item)}
                          />
                        )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div className="bottom">
        <div className="left">
          <EcnButton
            variant="outlined"
            color="secondary"
            className="btn-outlined"
            onClick={() => {
              // setActive((prev: any) => prev - 1);
              navigate("?view=user-detail&tab=mapped");
            }}
          >
            Back
          </EcnButton>
          <EcnButton
            className="btn-text"
            color="secondary"
            onClick={() => navigate("/add-user")}
          >
            Cancel
          </EcnButton>
        </div>
        <EcnButton
          variant="contained"
          className="btn-contained"
          onClick={nextHandler}
        >
          Next
        </EcnButton>
      </div>
    </div>
  );
};

export default MapingTable;

const option = [
  {
    field: "first_name",
    name: "First Name",
  },
  {
    field: "last_name",
    name: "Last Name",
  },
  {
    field: "email",
    name: "Email",
  },
  {
    field: "designation",
    name: "Designation",
  },
];
