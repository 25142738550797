import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { userSignout } from "../../redux/slices/userSlices/userSignoutSlice";
import { getProductList } from "../../redux/slices/Header/getProductListSlice";
import { getUserSettings } from "../../redux/slices/Header/getUserSettingsSlice";
import { getTimeZones } from "../../redux/slices/Header/getTimeZonesSlice";
import { updateUserSettings } from "../../redux/slices/Header/updateUserSettingsSlice";
import { getUnreadNotification } from "../../redux/slices/Header/getUnreadNotificationSlice";
import { patchStateData } from "../../redux/slices/dashboardSlices/patchStateDataSlice";

import { Icon } from "@iconify/react";
import {
  Autocomplete,
  Avatar,
  Badge,
  CircularProgress,
  Drawer,
  IconButton,
  Menu,
} from "@mui/material";

import EcnButton from "../StyledComponents/EcnButton";
import EcnInput from "../StyledComponents/EcnInput";

import NotificationPopup from "./NotificationPopup";
import SearchInput from "./SearchInput";

const IMG_BASE = process.env.REACT_APP_IMG_BASE;
const SIGNIN_REDIRECT = process.env.REACT_APP_AUTH_REDIRECT;

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { userInfo, loading } = useAppSelector((state) => state.userDetails);
  const { signOut } = useAppSelector((state) => state.signOut);
  const { productList } = useAppSelector((state) => state.getProductList);
  const { userSettings } = useAppSelector((state) => state.getUserSettings);
  const { timeZones } = useAppSelector((state) => state.getTimeZones);
  const { updateResponse } = useAppSelector((state) => state.updateUserSettings);
  const { notificationList } = useAppSelector((state) => state.getNotification);

  const [productAnchorEl, setProductAnchorEl] = useState<null | SVGSVGElement>(null);
  const openProduct = Boolean(productAnchorEl);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [click, setClick] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [loadingRegenerate, setLoadingRegenerate] = useState(false);
  const [settingsData, setSettingsData] = useState({
    timezone: {} as any,
    support_pin: "",
  });
  const [openNotification, setOpenNotification] = useState(false);
  const [totalNotification, setTotalNotification] = useState();
  const [anchorElNotification, setAnchorElNotification] =
    useState<null | HTMLElement>(null);

  const [userData, setUserData] = useState<any>();

  useEffect(() => {
    if (updateResponse) dispatch(getUserSettings());
  }, [updateResponse]);

  useEffect(() => {
    dispatch(getProductList());
    dispatch(getUserSettings());
    dispatch(getTimeZones());
    dispatch(getUnreadNotification("0"));
  }, []);

  useEffect(() => {
    if (notificationList) setTotalNotification(notificationList.unread_count);
  }, [notificationList]);

  useEffect(() => {
    if (userInfo) {
      setUserData(userInfo);
    }
  }, [userInfo]);

  useEffect(() => {
    if (
      userData &&
      (!userData.licenses || // Check if licenses is undefined or null
        userData.licenses.length === 0 || // Check if licenses array is empty
        !userData.licenses.find(
          (license: any) => license?.plan_id === "enterprise-recruiter-plan"
        )) // Check if no license with enterprise-recruiter-plan is found
    ) {
      navigate("/no-access");
    }
  }, [userData]);

  useEffect(() => {
    if (userSettings && timeZones) {
      setLoadingRegenerate(false);
      setSettingsData({
        timezone: timeZones.filter(
          (timezone: any) => timezone.tzCode === userSettings.timezone
        )[0],
        support_pin: userSettings.support_pin,
      });
    }
  }, [userSettings, timeZones]);

  useEffect(() => {
    if (signOut && click) {
      if (window.location.pathname !== "/signin")
        window.location.href = `${SIGNIN_REDIRECT}?origin=${window?.location.href}`;
    }
  }, [signOut]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOutHandler = () => {
    setAnchorEl(null);
    setClick(true);
    dispatch(userSignout(userInfo?.email));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Header menu functions
  const navigateProductHandler = (product: any) => {
    setProductAnchorEl(null);
    if (product.selected) return;
    else window.open(product.url, "_blank");
  };

  const timezoneChangeHandler = (value: any) => {
    dispatch(
      updateUserSettings({
        timezone: value.tzCode,
        regenerate_pin: false,
      })
    );
    setSettingsData({
      ...settingsData,
      timezone: value,
    });
  };

  const regeneratePinHandler = () => {
    setLoadingRegenerate(true);
    dispatch(
      updateUserSettings({
        timezone: settingsData.timezone.tzCode,
        regenerate_pin: true,
      })
    );
  };

  const openNotificationHandler = (event: any) => {
    dispatch(getUnreadNotification("4"));
    setAnchorElNotification(event.currentTarget);
    setOpenNotification(true);
  };

  return (
    <div className="header">
      <div className="left">
        <img src="../../logo.png" alt="l" />
      </div>
      <div className="center">
        <SearchInput />
      </div>
      <div className="right">
        <ul>
          <li onClick={openNotificationHandler}>
            {totalNotification ? (
              <Badge
                sx={{
                  "& .MuiBadge-badge": {
                    color: "#fff",
                    backgroundColor: "#0034BB",
                  },
                }}
                className="notification"
                badgeContent={totalNotification}
                max={100}
              >
                <Icon
                  icon="lucide:bell"
                  className={`icon ${openNotification ? "selected" : ""}`}
                  width="23"
                  height="23"
                />
              </Badge>
            ) : (
              <Icon
                icon="lucide:bell"
                className={`icon ${openNotification ? "selected" : ""}`}
              />
            )}
          </li>
          <NotificationPopup
            openNotification={openNotification}
            setOpenNotification={setOpenNotification}
            setTotalNotification={setTotalNotification}
            totalNotification={totalNotification}
            setAnchorElNotification={setAnchorElNotification}
            anchorElNotification={anchorElNotification}
          />
          <li onClick={() => setOpenSettings(true)}>
            <Icon
              icon="lucide:settings"
              className={`icon ${openSettings ? "selected" : ""}`}
            />
          </li>
          <Drawer
            anchor="right"
            open={openSettings}
            onClose={() => setOpenSettings(false)}
            className="settings-drawer"
          >
            <div className="wrapper">
              <div className="icon-container">
                <Icon
                  icon="ic:baseline-close"
                  height={24}
                  width={24}
                  color="#212121"
                  onClick={() => setOpenSettings(false)}
                  style={{ cursor: "pointer" }}
                />
              </div>

              <h3>Settings</h3>
              <div className="field">
                <div
                  className="start-tour-btn"
                  onClick={() => { navigate("/dashboard?tour=true"); setOpenSettings(false); }}
                >
                  <div className="cont">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.669438 1.75927C0.696444 1.53221 0.776048 1.31459 0.90192 1.12369C1.02779 0.932792 1.19646 0.773891 1.39452 0.659619C1.59258 0.545347 1.81457 0.478854 2.04283 0.465427C2.2711 0.452 2.49935 0.49201 2.70944 0.582274C3.77144 1.03627 6.15144 2.11527 9.17144 3.85827C12.1924 5.60227 14.3174 7.12527 15.2404 7.81627C16.0284 8.40727 16.0304 9.57927 15.2414 10.1723C14.3274 10.8593 12.2284 12.3623 9.17144 14.1283C6.11144 15.8943 3.75944 16.9603 2.70744 17.4083C1.80144 17.7953 0.787438 17.2083 0.669438 16.2313C0.531438 15.0893 0.273438 12.4963 0.273438 8.99427C0.273438 5.49427 0.530438 2.90227 0.669438 1.75927Z" fill="#212121" />
                    </svg>
                  </div>
                  <div className="content">
                    <h3>
                      Start the Tour
                    </h3>
                    <p>Show me around the portal</p>
                  </div>
                </div>
                <p>Time zone:</p>
                <Autocomplete
                  className="select"
                  disableClearable
                  options={timeZones}
                  value={settingsData.timezone}
                  onChange={(e, newVal) => timezoneChangeHandler(newVal)}
                  getOptionLabel={(option: any) => option.label}
                  renderInput={(params) => (
                    <EcnInput
                      {...params}
                      placeholder="Select or search for country"
                    />
                  )}
                />
              </div>

              <div className="field">
                <p>Terms and Conditions</p>
                <a
                  href="https://www.elevatecareernetwork.com/legal"
                  target="_blank"
                  rel="no-referrer"
                >
                  Learn more
                </a>
              </div>

              <div className="field">
                <p>Security and privacy</p>
                <a
                  href="https://www.elevatecareernetwork.com/privacy"
                  target="_blank"
                  rel="no-referrer"
                >
                  Learn more
                </a>
              </div>

              <div className="field">
                <p>Support PIN</p>
                <p className="sub">
                  Pin: {settingsData?.support_pin}{" "}
                  {loadingRegenerate ? (
                    <CircularProgress size={14} />
                  ) : (
                    <span onClick={regeneratePinHandler}>Regenerate</span>
                  )}
                </p>
              </div>
            </div>
          </Drawer>
          <li>
            <Icon
              id="product-button"
              aria-controls={openProduct ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openProduct ? "true" : undefined}
              onClick={(e) => setProductAnchorEl(e.currentTarget)}
              icon="lucide:grip"
              className={`icon ${openProduct ? "selected" : ""}`}
            />

            <Menu
              id="product-menu"
              anchorEl={productAnchorEl}
              open={openProduct}
              onClose={() => setProductAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "product-button",
              }}
              className="product-menu"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <h5>Products</h5>
              <div className="products-wrapper">
                {productList?.map((product: any) => (
                  <div
                    className="product"
                    onClick={() => navigateProductHandler(product)}
                  >
                    <div
                      className={`icon-wrapper ${product.selected ? "selected" : ""
                        }`}
                      onClick={() => navigateProductHandler(product)}
                    >
                      <Icon
                        icon={product.icon}
                        className="icon"
                        height={24}
                        width={24}
                      />
                    </div>
                    <div className="text-wrapper">
                      <p
                        className={`name ${product.selected ? "selected-name" : ""}`}
                      >
                        {product.short_name}
                      </p>
                      <p className="desc">{product.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Menu>
          </li>
          <li>
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{ maxWidth: "40px", maxHeight: "40px" }}
            >
              {userInfo?.display_picture ? (
                <Avatar
                  src={
                    userInfo?.display_picture === ""
                      ? `${IMG_BASE}/common/MicrosoftTeams-image (4).png`
                      : userInfo?.display_picture
                  }
                  alt="E"
                  className="avatar"
                />
              ) : (
                <Avatar
                  sx={{
                    background: "rgba(0, 52, 187, 0.13)",
                    color: "#0034BB",
                  }}
                >
                  {userInfo?.first_name[0] + userInfo?.last_name[0]}
                </Avatar>
              )}
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className="signout-menu"
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              style={{ marginLeft: "-20px" }}
            >
              <div className="user-menu">
                <div className="user">
                  <div className="left">
                    {userInfo?.display_picture ? (
                      <Avatar
                        src={
                          userInfo?.display_picture === ""
                            ? `${IMG_BASE}/common/MicrosoftTeams-image (4).png`
                            : userInfo?.display_picture
                        }
                        alt="E"
                        className="avatar"
                      />
                    ) : (
                      <Avatar
                        sx={{
                          background: "rgba(0, 52, 187, 0.13)",
                          color: "#0034BB",
                        }}
                      >
                        {userInfo?.first_name.charAt(0) +
                          userInfo?.last_name.charAt(0)}
                      </Avatar>
                    )}
                  </div>

                  <div className="right">
                    <p className="name">
                      {userInfo?.first_name + " " + userInfo?.last_name}
                    </p>
                    <p className="email">{userInfo?.email}</p>
                  </div>
                </div>

                <div className="divider"></div>
                <div className="btn-wrapper" onClick={signOutHandler}>
                  <EcnButton color="secondary" className="signout-btn">
                    Sign Out
                  </EcnButton>
                </div>
              </div>
            </Menu>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
