import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getMemberList } from "../../redux/slices/Group Creation/getMemberListSlice";
import GloableAccess from "./GloableAccess";
import JobAccess from "./JobAccess";

const AccessTab = () => {
  const dispatch = useAppDispatch();

  const { profile, errorData } = useAppSelector((state: any) => state.editUser);

  const [activeStep, setActiveStep] = useState<number>(0);
  const handleChange = (event: any, newValue: any) => {
    setActiveStep(newValue);
  };

  useEffect(() => {
    dispatch(
      getMemberList({
        filters: [
          { type: "plan", plan: "enterprise-recruiter-plan-platinum" },
          { type: "plan", plan: "enterprise-recruiter-plan-premium" },
          { type: "plan", plan: "enterprise-recruiter-plan" },
        ],
        op: "or",
        skip: 0,
        limit: 1000,
      })
    );
  }, [profile]);

  return (
    <div className="access-tab">
      <Tabs value={activeStep} onChange={handleChange} className="tabs">
        <Tab label="Global Access" />
        <Tab label="Job Access" />
      </Tabs>

      {activeStep === 0 && <GloableAccess />}
      {activeStep === 1 && <JobAccess />}
    </div>
  );
};

export default AccessTab;
