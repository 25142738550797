import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EcnButton from "../StyledComponents/EcnButton";
import { inviteUser } from "../../redux/slices/InviteUserFlow/inviteUserSlices";

const StyledTableCell = styled(TableCell)(({}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8F8F8",
    color: "#212121",
    borderRight: "1px solid rgba(0,0,0,0.2)",
    width: "200px",
    fontSize: 14,
    fontWeight: "500",
    paddingLeft: "40px",
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "left",
    fontWeight: "400",
    color: "#212121",
    paddingLeft: "40px",
  },
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Preview: React.FC<{ role: any; userData: any; setActive: any }> = ({
  role,
  userData,
  setActive,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const withTab = searchParams.get("with");
  const [data, setData] = useState<any>();
  const { inviteUserData } = useAppSelector(
    (state: any) => state.inviteUsersData
  );
  useEffect(() => {
    if (role) {
      let new_data = userData.map((obj: any) => ({ ...obj, roles: role }));
      setData(new_data);
    }
  }, [role]);

  useEffect(() => {
    if (inviteUserData) {
      navigate("/add-user");
    }
  }, [inviteUserData]);

  const inviteUserHadler = () => {
    dispatch(inviteUser(data));
  };
  const backHandler = () => {
    if (withTab === null) {
      setActive((pre: any) => pre - 1);
      navigate("?view=permission");
    } else {
      setActive((pre: any) => pre - 1);
      navigate("?view=permission&with=mapped");
    }
  };

  return (
    <div className="preview">
      <div className="top">
        <div className="list-user">
          {userData?.map((item: any) => (
            <div className="user">
              <Avatar>{item?.first_name[0]}</Avatar>

              <div className="details">
                <h1>{item?.first_name + " " + item?.last_name}</h1>
                <p>{item?.email}</p>
              </div>
            </div>
          ))}
        </div>
        <h1 className="title">Assigned Role</h1>
        <TableContainer component={Paper} variant="outlined">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">
                  Pre-defined Roles
                </StyledTableCell>

                <StyledTableCell
                  align="left"
                  style={{ paddingLeft: "100px", borderRight: "none" }}
                >
                  Description
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {role?.map((item: any) => (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="left"
                    style={{
                      borderRight: "1px solid rgba(0,0,0,0.2)",
                      width: "100px",
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox checked />}
                      value="member"
                      label={
                        item === "member"
                          ? "Member"
                          : item === "spot-admin"
                          ? "Spot Admin"
                          : item === "recruiter-admin"
                          ? "Recruiter Admin"
                          : item === "spot-member"
                          ? "Spot Member"
                          : ""
                      }
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#000000",
                        },
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ paddingLeft: "100px" }}
                  >
                    Commonly used to demonstrate the visual form of a document
                    or a typeface without relying on meaningful content.
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="bottom">
        <div className="left">
          <EcnButton
            variant="outlined"
            color="secondary"
            className="btn-outlined"
            onClick={backHandler}
          >
            Back
          </EcnButton>
          <EcnButton
            className="btn-text"
            color="secondary"
            onClick={() => navigate("/add-user")}
          >
            Cancel
          </EcnButton>
        </div>
        <EcnButton
          variant="contained"
          className="btn-contained"
          onClick={inviteUserHadler}
        >
          Send invite
        </EcnButton>
      </div>
    </div>
  );
};

export default Preview;
