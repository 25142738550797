import moment from "moment";
import React from "react";

const ExperienceCard: React.FC<{ data: any }> = ({ data }) => {
  console.log(
    "check",
    data?.created_by?.work_experiences?.[0]?.end_date === "2030-10-05T14:48:00.000Z",
    data?.created_by?.work_experiences?.[0]?.end_date
  );
  return (
    <div className="experience-card">
      <h1>Experience</h1>
      {data?.created_by?.work_experiences?.map((item: any) => (
        <div className="experience-list">
          <h2>{item?.title}</h2>
          <h3>{item?.company}</h3>
          <p>
            {moment(item?.start_date).format("MMMM YYYY")} -{" "}
            {item?.end_date === "0000-00-00T00:00:00Z" ||
            item?.end_date === "0001-01-01T00:00:00Z" ||
            item?.end_date === "2030-10-05T14:48:00Z"
              ? "Present"
              : moment(item?.end_date).format("MMMM YYYY")}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ExperienceCard;
