import React, { ChangeEvent, useEffect, useState } from "react";

import { Icon } from "@iconify/react";
import EcnButton from "../StyledComponents/EcnButton";
import EcnInput from "../StyledComponents/EcnInput";
import { useNavigate, useSearchParams } from "react-router-dom";

const detailTemplate = {
  first_name: "",
  last_name: "",
  designation: "",
  email: "",
};

const UserDetail: React.FC<{
  setData: any;
  setActive: any;
  setUserData: any;
  userData: any;
  setFileName: any;
  fileName: any;
}> = ({ setData, setActive, setUserData, userData, fileName, setFileName }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const [index, setIndex] = useState<any>(
    userData?.length === 0 ? 1 : userData?.length
  );

  const [error, setError] = useState({
    state: false,
    message: "",
  });
  const [isValidEmail, setIsValidEmail] = useState<any>(false);

  useEffect(() => {
    if (userData?.length === 0) {
      const tempWork = userData.slice();
      tempWork.push({ ...detailTemplate });
      setUserData(tempWork);
    }
  }, [userData]);
  useEffect(() => {
    if (tab === "mapped") {
      setIndex(1);
    }
  }, [tab]);

  const fileHandler = (e: any) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    setFileName(file);
    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const csvText = e.target.result;
        parseCSV(csvText);
      };

      reader.readAsText(file);
    }
  };

  const parseCSV = (csvText: any) => {
    const lines = csvText.split("\n");
    const headers = lines[0].split(",");
    const parsedData: any = [];

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");

      if (currentLine.length === headers.length) {
        const row: any = {};
        for (let j = 0; j < headers.length; j++) {
          row[headers[j].trim()] = currentLine[j].trim();
        }
        parsedData.push(row);
      }
    }

    setData(parsedData);
  };

  const handleChange = (e: any, input: string, i: number, value?: any) => {
    const tempWork = userData.slice();
    if (!tempWork.length || !tempWork[i]) {
      tempWork.push({ ...detailTemplate });
    }

    let newValue: any = "";
    if (input === "first_name") {
      newValue = e.target.value;
    } else if (input === "last_name") {
      newValue = e.target.value;
    } else if (input === "designation") {
      newValue = e.target.value;
    } else {
      newValue = e.target.value;
      setIsValidEmail(false);
    }

    const newWork = tempWork.map((item: any, index: number) =>
      index === i
        ? {
            ...item, // <-- shallow copy element
            [input]: newValue, // <-- update property
          }
        : item
    );

    setUserData(newWork);
  };
  function areAllFieldsNotEmpty(obj: any) {
  
    for (const key in obj) {
      if (key != "designation") {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        
          return false;
        }
      }
      if (key === "email") {
        if (
          !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            obj[key]
          )
        ) {
          setIsValidEmail(true);
          return false;
        }
      }
    }
    return true;
  }

  const nextHandler = () => {
    if (fileName === "") {
      const allFieldsNotEmpty = userData.every(areAllFieldsNotEmpty);

      if (allFieldsNotEmpty) {
        setIndex(userData?.length);
        setActive((next: any) => next + 1);

        navigate("?view=permission");
      } else {
        setError({ ...error, state: true });
      }
    } else {
      navigate("?view=mapping");
    }
  };
  const addMoreHandler = () => {
    setIsValidEmail(false);
    setIndex((prev: any) => prev + 1);
    setError({ ...error, state: false });
    setUserData([...userData, detailTemplate]);
  };
  const deleteHandler = () => {
    setIndex((prev: any) => prev - 1);
    setError({ ...error, state: false });
    userData.splice(userData?.length - 1, 1);

    setUserData(userData);
  };
  const detailGrid = (n: number) => {
    return (
      <div className="row">
        <div className="col">
          <p>Add email address(es)*</p>
          <EcnInput
            className="ecn-input"
            placeholder="Enter work email address"
            onChange={(e) => handleChange(e, "email", n)}
            value={tab === "mapped" ? "" : userData[n]?.email}
          />
          {error?.state && userData[n]?.email === "" && (
            <p className="error">Enter email</p>
          )}
          {isValidEmail &&
            !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              userData[n]?.email
            ) && <p className="error">Enter valid email</p>}
        </div>
        <div className="col">
          <p>First name*</p>
          <EcnInput
            className="ecn-input"
            placeholder="Enter first name"
            onChange={(e) => handleChange(e, "first_name", n)}
            value={tab === "mapped" ? "" : userData[n]?.first_name}
          />
          {error?.state && userData[n]?.first_name === "" && (
            <p className="error">Enter First name</p>
          )}
        </div>
        <div className="col">
          <p>Last name*</p>
          <EcnInput
            className="ecn-input"
            placeholder="Enter last name"
            onChange={(e) => handleChange(e, "last_name", n)}
            value={tab === "mapped" ? "" : userData[n]?.last_name}
          />
          {error?.state && userData[n]?.last_name === "" && (
            <p className="error">Enter Last name</p>
          )}
        </div>

        <div className="col">
          <p>Designation</p>
          <EcnInput
            className="ecn-input"
            placeholder="Enter designation"
            onChange={(e) => handleChange(e, "designation", n)}
            value={tab === "mapped" ? "" : userData[n]?.designation}
          />
        </div>
      </div>
    );
  };
  const removeHandler = () => {
    setFileName("");
    window.location.reload();
  };

  return (
    <div className="user-detail">
      <div className="top">
        {fileName?.length === 0 && (
          <div className="user">
            <h2>Create a bunch of new users at the same time</h2>
            {[...Array(index)].map((n: number, ind: number) => (
              <div key={ind}>{detailGrid(ind)}</div>
            ))}

            <div className="button">
              <EcnButton
                variant="outlined"
                className="btn-outlined"
                onClick={addMoreHandler}
              >
                Add more user
              </EcnButton>
              {index !== 1 && (
                <Icon
                  icon="ic:outline-delete"
                  color="#7F00BB"
                  width={30}
                  height={30}
                  style={{ cursor: "pointer" }}
                  onClick={deleteHandler}
                />
              )}
            </div>
          </div>
        )}
        {((userData[0]?.email === "" &&
          userData[0]?.first_name === "" &&
          userData[0]?.last_name === "") ||
          tab === "mapped") && (
          <div className="import">
            <h2>Import multiple users from CSV file</h2>
            <p>
              Please make sure the uploaded CSV file contain the work email
              IDs,First Name and Last Name of the users you want to add
            </p>
            <div className="input-name">
              <div className="input">
                <label htmlFor="file-upload" className="custom-file-upload">
                  <Icon
                    icon="bi:filetype-csv"
                    width={40}
                    height={40}
                    color="#374151"
                  />
                  Upload CSV file
                  {fileName?.length === undefined && (
                    <h3 className="replace">replace</h3>
                  )}
                </label>
                <input
                  id="file-upload"
                  type="file"
                  onChange={fileHandler}
                  accept=".csv"
                />
              </div>
              {fileName?.length === undefined && (
                <h3>
                  {fileName?.name}{" "}
                  {fileName != undefined && (
                    <Icon
                      icon="mdi:delete-outline"
                      width={20}
                      height={20}
                      color="#374151"
                      onClick={removeHandler}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </h3>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="bottom">
        <EcnButton
          color="secondary"
          className="btn-text"
          onClick={() => navigate("/add-user")}
        >
          Cancel
        </EcnButton>
        <EcnButton
          variant="contained"
          className="btn-contained"
          onClick={nextHandler}
        >
          Next
        </EcnButton>
      </div>
    </div>
  );
};

export default UserDetail;
