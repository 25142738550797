import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import Cards from "./Cards";
import { Pagination, Skeleton } from "@mui/material";
import { useQueryParams } from "../../../helpers/HOC/useQueryParams";
import CandidateQuickView from "./CandidateQuickView";

const CardGrid = () => {
  const [searchParams, updateQueryParams] = useQueryParams();
  const pageNo = searchParams.get("page") || "";
  const id = searchParams.get("id") || "";
  const [pageChanged, setPageChanged] = useState(false);

  const { filterJobData, loading: filterJobDataLoading } = useAppSelector(
    (state: any) => state.getFilterJobLists
  );

  const [page, setPage] = useState<number>(Number(pageNo) || 1);

  useEffect(() => {
    if (!id && filterJobData)
      updateQueryParams({
        id: filterJobData?.result?.[0]?._id,
        title: encodeURI(filterJobData?.result?.[0]?.job_title)
      });
  }, [filterJobData]);

  useEffect(() => {
    // Select the first card when page changed
    if (pageChanged && !filterJobDataLoading) {
      updateQueryParams({
        id: filterJobData?.result?.[0]?._id,
        title: encodeURI(filterJobData?.result?.[0]?.job_title)
      });
    }
  }, [pageChanged, filterJobDataLoading, filterJobData]);

  const pageHandler = (e: any, page: any) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    updateQueryParams({ page: page });
    setPage(page);
    setPageChanged(true);
  };

  return (
    <div className="card-grid">
      <div className="job-list">
        {filterJobDataLoading ? (
          new Array(5).fill(0).map((item: any, index) => (
            <div
              key={index}
              style={{
                borderRight: "1px solid rgba(33, 33, 33, 0.20)",
                borderBottom: "1px solid rgba(33, 33, 33, 0.20)",
                borderLeft: "1px solid rgba(33, 33, 33, 0.20)",
                padding: 23
              }}
            >
              <Skeleton width={"100%"} height={25} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20
                }}
              >
                <Skeleton width={"40%"} height={25} />
                <Skeleton width={"20%"} height={25} />
              </div>
            </div>
          ))
        ) : (
          <>
            {filterJobData?.result?.map((item: any) => <Cards item={item} />)}
            <div className="pagination-wrapper">
              <Pagination
                page={page}
                onChange={pageHandler}
                count={
                  filterJobData?.total_count
                    ? Math.ceil(filterJobData.total_count / 10)
                    : 1
                }
                color="primary"
                className="job-pagination"
              />
            </div>
          </>
        )}
      </div>
      <div className="candidates-wrapper">
        <CandidateQuickView />
      </div>
    </div>
  );
};

export default CardGrid;
