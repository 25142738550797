import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { allUserSlices } from "./slices/userSlices/allUserSlices";
import { allJobSlices } from "./slices/JobSlices/allJobSlices";
import { allCandidatesSlices } from "./slices/CandidatesSlices/allCandidatesSlices";
import { allJobsSlices } from "./slices/Jobs/allDashBoard";
import { allGroupCreationSlices } from "./slices/Group Creation/allGroupCreationSlices";
import { allSettingsSlices } from "./slices/Settings/allSettingsSlices";
import { allInviteSlices } from "./slices/InviteUserFlow/allInviteSlices";
import { allJobPromotionsSlices } from "./slices/JobPromotions/allJobPromotionsSlices";
import { allAnalytisSlices } from "./slices/Analytics/allAnalytisSlices";

import { allMailsSlices } from "./slices/MailsSlices/allMailsSlices";

import { allNotesSlices } from "./slices/Notes/allNotesSlices";
import { allEventsSlices } from "./slices/EventSlices/allEventsSlices";
import { allHeaderSlices } from "./slices/Header/allHeaderSlices";
import { allDashboardSlices } from "./slices/dashboardSlices/allDashboardSlices";

export const store = configureStore({
  reducer: {
    ...allUserSlices,
    ...allJobSlices,
    ...allCandidatesSlices,
    ...allJobsSlices,
    ...allGroupCreationSlices,
    ...allSettingsSlices,
    ...allInviteSlices,
    ...allJobPromotionsSlices,
    ...allAnalytisSlices,
    ...allMailsSlices,
    ...allNotesSlices,
    ...allEventsSlices,
    ...allHeaderSlices,
    ...allDashboardSlices,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
