import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import UserDetail from "../components/InviteUser/UserDetail";
import MapingTable from "../components/InviteUser/MapingTable";
import RoleAssign from "../components/InviteUser/RoleAssign";
import Preview from "../components/InviteUser/Preview";
import { useNavigate, useSearchParams } from "react-router-dom";

const QontoConnector: any = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: "1px solid #7F00BB",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: "1px solid #7F00BB",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
      marginBottom: "-30px",
    },
    "& .MuiStepLabel-labe": {
      marginTop: "-20px",
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div
          style={{
            width: "20px",
            height: "20px",
            background: "#7F00BB",
            borderRadius: "100%",
            textAlign: "center",
          }}
        >
          {" "}
          <Icon icon="octicon:check-24" />
        </div>
      ) : active ? (
        <div
          style={{
            width: "20px",
            height: "20px",
            background: "transparent",
            borderRadius: "100%",
            border: "2px solid #7F00BB",
          }}
        ></div>
      ) : (
        <div
          style={{
            width: "20px",
            height: "20px",
            background: "transparent",
            borderRadius: "100%",
            border: "2px solid #D1D5DB",
          }}
        ></div>
      )}
    </QontoStepIconRoot>
  );
}

const InviteUser = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");
  const tab = searchParams.get("tab");
  const [active, setActive] = useState<any>(0);
  const [mapData, setMapData] = useState<any>();
  const [selectedOption, setSelectedOption] = useState<any>([]);
  const [checkFiled, setCheckField] = useState({
    email: false,
    first_name: false,
    last_name: false,
  });

  const [data, setData] = useState<any>();
  const [userData, setUserData] = useState<any>([]);
  const [fileName, setFileName] = useState<any>("");
  const [role, setRole] = useState<any>(["member"]);
  const steps = ["Email", "Permissions", "Invite"];

  useEffect(() => {
    if (userData?.length === 0) {
      navigate("?view=user-detail");
    }
  }, []);

  return (
    <div className="inviteUser">
      <h1>Invite your team members</h1>
      <div className="stepper">
        <Stepper alternativeLabel activeStep={active} connector={<QontoConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      {view === "user-detail" && (
        <UserDetail
          setData={setData}
          setActive={setActive}
          setUserData={setUserData}
          userData={userData}
          setFileName={setFileName}
          fileName={fileName}
        />
      )}
      {view === "mapping" && (
        <MapingTable
          data={data}
          setUserData={setUserData}
          setActive={setActive}
          setMapData={setMapData}
          mapData={mapData}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setCheckField={setCheckField}
          checkFiled={checkFiled}
        />
      )}
      {view === "permission" && (
        <RoleAssign setRole={setRole} role={role} setActive={setActive} />
      )}
      {view === "preview" && (
        <Preview role={role} userData={userData} setActive={setActive} />
      )}
    </div>
  );
};

export default InviteUser;
