import React, { useEffect, useState } from "react";
import EcnButton from "../../StyledComponents/EcnButton";
import { Icon } from "@iconify/react";

import EcnStyledDataGridUpdated from "../../StyledComponents/EcnStyledDataGridUpdated";
import {
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Box
} from "@mui/material";
import { useQueryParams } from "../../../helpers/HOC/useQueryParams";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { candidatesList } from "../../../redux/slices/CandidatesSlices/candidatesListSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import EcnSelect from "../../StyledComponents/V2/EcnSelect";
import { bulkUpdateApplication } from "../../../redux/slices/JobSlices/bulkUpdateApplicationSlice";
import { enqueueSnackbar } from "notistack";
import EcnCheckboxFilled from "../../StyledComponents/V2/EcnCheckboxFilled";
import Lottie from "react-lottie";
import NoJobApplicantsJson from "../../../assets/lottie/no_job_applicants.json";
import ShareJobDialog from "./ShareJobDialog";
import { getPrivateJobBoardUrl } from "../../../redux/slices/JobSlices/getPrivateJobBoardUrl";
import ResumeDrawer from "./ResumeDrawer";

const headerTextStyle = {
  color: "#121619",
  fontSize: "14px",
  fontWeight: "500"
};

const applicationStatus = [
  "Not Yet Reviewed",
  "Decline",
  "Maybe",
  "Invite For Interview",
  "Offer",
  "Offer accepted"
];

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOBS_BASE =
  GATEWAY_BASE == "https://api.dev.ecndev.io"
    ? "https://jobs.dev.ecndev.io"
    : "https://jobs.onefinnet.com";

const columns: any = [
  {
    field: "name",
    minWidth: 150,
    flex: 1,
    sortable: true,
    renderHeader: () => (
      <Stack direction="row" alignItems="left">
        <p style={headerTextStyle}>Candidate Name</p>
      </Stack>
    ),
    renderCell: (params: any) => (
      <Stack direction="column" spacing={0.8}>
        <div className="name">
          {params?.row?.created_by?.first_name +
            " " +
            params?.row?.created_by?.last_name}
        </div>
        <div className="email">{params?.row?.created_by?.email}</div>
      </Stack>
    )
  },

  {
    field: "resume",
    minWidth: 120,
    flex: 1,
    sortable: false,
    renderHeader: () => (
      <Stack direction="row" alignItems="left">
        <p style={headerTextStyle}>Resume</p>
      </Stack>
    ),
    renderCell: (params: any) => {
      return (
        <Stack direction="row" spacing={1}>
          <div
            className={`resume-cell ${params.row.resume == "" ? "resume-cell-disabled" : ""}`}
          >
            <p className="icon-wrapper">
              <Icon
                icon="lucide:file"
                width={15}
                height={15}
                color="rgba(0, 52, 187, 1)"
              />
            </p>
            <p className="text">Resume</p>
          </div>
        </Stack>
      );
    }
  },

  {
    field: "ai_rating",
    headerName: "AI Rating",
    minWidth: 100,
    flex: 1,
    sortable: true,
    renderHeader: () => (
      <Stack direction="row" alignItems="center">
        <Icon icon="ep:magic-stick" width={16} height={16} color="#667085" />
        <p style={headerTextStyle}>AI Rating</p>
      </Stack>
    ),
    renderCell: (params: any) => (
      <Stack direction="row" spacing={1}>
        <p>

          {typeof params.row.avg_relevancy_score === "number"
            ? Math.round((params.row.avg_relevancy_score / params?.row?.total_relevancy_score) * 100) +
            "%"
            : "N/A"}
        </p>
      </Stack>
    )
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 80,
    flex: 1,
    sortable: false,
    renderHeader: () => (
      <Stack direction="row" alignItems="left">
        <p style={headerTextStyle}>Status</p>
      </Stack>
    ),
    renderCell: (params: any) => (
      <Stack>
        {params?.row?.application_status === 0
          ? "Not Yet Reviewed"
          : params?.row?.application_status === 1
            ? "Decline"
            : params?.row?.application_status === 2
              ? "Maybe"
              : params?.row?.application_status === 3
                ? "Invite for Interview"
                : params?.row?.application_status === 4
                  ? "Offer"
                  : "Offer Accepted"}
      </Stack>
    )
  },
  {
    field: "timestamp",
    headerName: "Applied On",
    minWidth: 125,
    flex: 1,
    sortable: false,
    renderHeader: () => (
      <Stack direction="row" alignItems="left">
        <p style={headerTextStyle}>Applied On</p>
      </Stack>
    ),
    renderCell: (params: any) => (
      <Stack sx={{ width: "90%" }} spacing={1}>
        {moment.unix(params?.row?.timestamp).format("ll")}
      </Stack>
    )
  }
];

const CandidateQuickView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useQueryParams();
  const id = searchParams.get("id");
  const title = searchParams.get("title") || "";

  const [selectedStatus, setSelectedStatus] = useState(0);
  const [statusToBeChanged, setStatusToBeChanged] = useState<{
    selectedRowsId: Array<string>;
    selectedStatus: number;
  }>();
  const [resume, setResume] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const { candidatesListData, loading } = useAppSelector(
    (state: any) => state.candidateLists
  );
  const { loading: loadingBulkUpdate, error: errorBulkUpdate } = useAppSelector(
    (state) => state.bulkUpdateApplication
  );
  const { privateJobBoardUrl } = useAppSelector(
    (state) => state.getPrivateJobBoardUrl
  );
  const [rows, setRows] = useState(candidatesListData?.applications || []);

  const [selectedRows, setSelectedRows] = useState([]);
  const shouldShowMore = candidatesListData
    ? candidatesListData.total_job_applicants > 10
    : false;
  const [shareJobDialogOpen, setShareJobDialogOpen] = useState(false);

  useEffect(() => {
    if (!privateJobBoardUrl) dispatch(getPrivateJobBoardUrl());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(candidatesList({ id: id, limit: 10, st: -1, sort: "status:1" }));
    }
  }, [id]);


  useEffect(() => {
    if (candidatesListData?.applications) {
      setRows(candidatesListData.applications);
    }
  }, [candidatesListData]);

  useEffect(() => {
    if (
      statusToBeChanged !== undefined &&
      !loadingBulkUpdate &&
      !errorBulkUpdate
    ) {
      const newRows = rows.map((row: any) => {
        const sameRow = statusToBeChanged.selectedRowsId.find((id) => {
          return id == row._id;
        });
        if (sameRow !== undefined) {
          const newRow = { ...row };
          newRow.application_status = statusToBeChanged.selectedStatus;
          return newRow;
        }
        return row;
      });
      setRows(newRows as never[]);
      enqueueSnackbar("Candidates status updated successfully", {
        variant: "success"
      });
    }
  }, [statusToBeChanged, loadingBulkUpdate, errorBulkUpdate]);

  const promoteJobHandler = () => {
    navigate(`/job/promotion/${id}`);
  };

  const viewDetailHandler = () => {
    navigate(`?view=details&id=${id}`);
  };

  const showMoreCandidatesHandler = () => {
    navigate(`/job/applicants?id=${id}`);
  };

  const selectionModelHandler = (newSelection: any) => {
    setSelectedRows(newSelection);

    const lastSelectedItem: any = rows.find((item: any) => {
      return item._id == newSelection[newSelection.length - 1];
    });
    const statusId = applicationStatus.findIndex((item) => {
      return (
        item == applicationStatus[lastSelectedItem?.application_status || 0]
      );
    });
    if (statusId > 0) {
      setSelectedStatus(statusId);
    } else {
      setSelectedStatus(0);
    }
  };

  // CTA Handlers

  const mailClickHandler = () => {
    navigate(
      `/mails?view=compose&id=${id}&back-to=inbox&applicants=${selectedRows.join(
        ","
      )}`
    );
  };

  const statusChangeHandler = (ev: SelectChangeEvent<unknown>) => {
    const newStatusNumber = Number(ev.target.value);
    setSelectedStatus(newStatusNumber);
    dispatch(
      bulkUpdateApplication({
        jobId: id || "",
        action: "bulk_status_change",
        application_ids: selectedRows,
        status: newStatusNumber
      })
    );
    // A useEffect hook will change table data when loading and error are false
    setStatusToBeChanged({
      selectedRowsId: selectedRows,
      selectedStatus: newStatusNumber
    });
  };

  const handleOnCellClick = (params: any) => {
    if (params.field === "name") {
      navigate(`/candidates/details?id=${params.id}&application_id=${id}`);
    }
    if (params.field === "resume") {
      if (params.row.resume == "") return;
      setOpenModal(true);

      let originalUrl = params.row.resume;
      let oldBaseUrl = "https://core.api.elevatecareernetwork.com/";
      let newBaseUrl = "https://api.onefinnet.com/core-backend/v1/";

      if (originalUrl.includes(oldBaseUrl)) {
        let updatedUrl = originalUrl.replace(oldBaseUrl, newBaseUrl);
        setResume(updatedUrl);
      } else {
        setResume(params.row.resume);
      }
    }
  };

  return (
    <div className="job-quick-view">
      <div className="job-quick-view-header">
        <h4 className="job-title">{decodeURI(title)}</h4>
        <EcnButton
          startIcon={<Icon icon="uil:arrow-growth" />}
          variant="contained"
          className="promote-btn"
          onClick={promoteJobHandler}
        >
          Promote Job
        </EcnButton>
      </div>

      <div className="table-container">
        <div className="head">
          <p className="heading">Candidates</p>
          <EcnButton
            startIcon={<Icon icon="lucide:expand" height={16} />}
            variant="outlined"
            className="detail-btn"
            onClick={viewDetailHandler}
          >
            View Details
          </EcnButton>
        </div>

        <div className="job-cta-wrapper">
          {loading ? (
            <>
              <Skeleton height={25} width={148} />
              <div className="ctas">
                <Skeleton height={43} width={170} />
              </div>
            </>
          ) : (
            <>
              <p className="candidate-count">
                Not yet reviewed: {candidatesListData?.total_new_applicants}
              </p>

              <div className="ctas">
                {selectedRows.length > 0 && (
                  <>
                    <EcnSelect
                      value={selectedStatus}
                      className="ecn-select"
                      defaultValue={0}
                      onChange={statusChangeHandler}
                    >
                      {applicationStatus.map((status, index) => {
                        return (
                          <MenuItem value={index} key={status}>
                            {status}
                          </MenuItem>
                        );
                      })}
                    </EcnSelect>

                    <IconButton className="mail-btn" onClick={mailClickHandler}>
                      <Icon icon="lucide:mail" />
                    </IconButton>
                  </>
                )}

                <EcnButton
                  startIcon={<Icon icon="tabler:file-text-ai" />}
                  variant="contained"
                  className="screen-btn"
                >
                  Screen Resume
                </EcnButton>
              </div>
            </>
          )}
        </div>

        <div className="table-wrapper">
          {loading ? (
            <>
              <Skeleton height={50} width="100%" />
              {new Array(5).fill(0).map((_, i) => {
                return (
                  <div
                    key={i}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Skeleton height={35} width="30%" />
                    <Skeleton height={35} width="15%" />
                    <Skeleton height={35} width="15%" />
                    <Skeleton height={35} width="15%" />
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <Box
                height={
                  rows?.length == 0 ? undefined : "calc(100vh - 538.37px)"
                }
                minHeight={rows?.length == 0 ? undefined : "20dvh"}
              >
                <EcnStyledDataGridUpdated
                  disableColumnMenu
                  disableColumnResize={true}
                  columns={columns}
                  className="candidate-quick-view-table"
                  onCellClick={handleOnCellClick}
                  rows={rows}
                  getRowId={(row: any) => row._id}
                  onRowSelectionModelChange={selectionModelHandler}
                  slots={{
                    baseCheckbox: (props: any) => (
                      <EcnCheckboxFilled {...props} />
                    )
                  }}
                />
              </Box>
              {rows?.length == 0 && (
                <div className="no-applicants-fallback">
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: NoJobApplicantsJson,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice"
                      }
                    }}
                    width={184}
                    height={138}
                  />
                  <div className="text">Oh No! You have no candidates yet</div>
                  <EcnButton
                    onClick={() => {
                      setShareJobDialogOpen(true);
                    }}
                    className="share-job-button"
                    variant="outlined"
                  >
                    <Icon icon="lucide:share-2" style={{ marginRight: 4 }} />
                    Share Job
                  </EcnButton>
                </div>
              )}
              {shouldShowMore && (
                <div className="show-more-button-wrapper">
                  <EcnButton
                    variant="outlined"
                    onClick={() => {
                      navigate(`?view=details&id=${id}`);
                    }}
                  >
                    Show More
                  </EcnButton>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ShareJobDialog
        open={shareJobDialogOpen}
        setOpen={setShareJobDialogOpen}
        link={`${JOBS_BASE}/${privateJobBoardUrl?.suggested_url || ""}/job/${id}`}
      />
      <ResumeDrawer
        resume={resume}
        setResume={setResume}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </div>
  );
};

export default CandidateQuickView;
