import {
  Checkbox,
  FormControlLabel,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import EcnButton from "../StyledComponents/EcnButton";

const StyledTableCell = styled(TableCell)(({}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8F8F8",
    color: "#212121",
    borderRight: "1px solid rgba(0,0,0,0.2)",
    width: "200px",
    fontSize: 14,
    fontWeight: "500",
    paddingLeft: "40px",
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "left",
    fontWeight: "400",
    color: "#212121",
    paddingLeft: "40px",
  },
}));

const StyledTableRow = styled(TableRow)(({}) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const RoleAssign: React.FC<{ setRole: any; role: any; setActive: any }> = ({
  setRole,
  role,
  setActive,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const withTab = searchParams.get("with");

  const handleChange = (event: any, value: any) => {
    if (event.target.checked) {
      setRole([...role, value]);
    } else {
      let rol = role?.filter(function (item: any) {
        return item !== value;
      });
      setRole(rol);
    }
  };

  const backHandler = () => {
    if (withTab === null) {
      setActive((pre: any) => pre - 1);
      navigate("?view=user-detail");
    } else {
      setActive((pre: any) => pre - 1);
      navigate("?view=mapping");
    }
  };

  const nextHandler = () => {
    if (withTab === null) {
      setActive((next: any) => next + 1);
      navigate("?view=preview");
    } else {
      setActive((next: any) => next + 1);
      navigate("?view=preview&with=mapped");
    }
  };
  return (
    <div className="role">
      <TableContainer component={Paper} variant="outlined">
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Pre-defined Roles</StyledTableCell>

              <StyledTableCell
                align="left"
                style={{ paddingLeft: "100px", borderRight: "none" }}
              >
                Description
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <StyledTableRow>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={{
                  borderRight: "1px solid rgba(0,0,0,0.2)",
                  width: "100px",
                }}
              >
                <FormControlLabel
                  control={<Checkbox checked />}
                  // onClick={(e) => handleChange(e, "member")}
                  value="member"
                  label="Member"
                  disabled
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#000000",
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={{ paddingLeft: "100px" }}
              >
                This is the default role that will be given to every invited person
                of your organization.
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={{ borderRight: "1px solid rgba(0,0,0,0.2)" }}
              >
                <FormControlLabel
                  control={<Checkbox checked={role.includes("spot-admin")} />}
                  onClick={(e) => handleChange(e, "spot-admin")}
                  value="spot-admin"
                  label="Spot Admin"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#000000",
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={{ paddingLeft: "100px" }}
              >
                This role will be responsible for managing all the enterprise spot
                members and to assist them when ever required.
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={{ borderRight: "1px solid rgba(0,0,0,0.2)" }}
              >
                <FormControlLabel
                  control={<Checkbox checked={role.includes("recruiter-admin")} />}
                  onClick={(e) => handleChange(e, "recruiter-admin")}
                  value="recruiter-admin"
                  label="Recruiter Admin"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#000000",
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={{ paddingLeft: "100px" }}
              >
                This role will be responsible for posting job and managing all the
                jobs and recruiters on the platform.
              </StyledTableCell>
            </StyledTableRow>
            {/*<StyledTableRow>
              <StyledTableCell
                component="th"
                scope="row"
                align="left"
                style={{ borderRight: "1px solid rgba(0,0,0,0.2)" }}
              >
                <FormControlLabel
                  control={<Checkbox checked={role.includes("spot-member")} />}
                  onClick={(e) => handleChange(e, "spot-member")}
                  value="spot-member"
                  label="Spot Member"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#000000",
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={{ paddingLeft: "100px" }}
              >
                Commonly used to demonstrate the visual form of a document or a
                typeface without relying on meaningful content.
              </StyledTableCell>
            </StyledTableRow>*/}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="bottom">
        <div className="left">
          <EcnButton
            variant="outlined"
            color="secondary"
            className="btn-outlined"
            onClick={backHandler}
          >
            Back
          </EcnButton>
          <EcnButton
            className="btn-text"
            color="secondary"
            onClick={() => navigate("/add-user")}
          >
            Cancel
          </EcnButton>
        </div>
        <EcnButton
          variant="contained"
          className="btn-contained"
          onClick={nextHandler}
        >
          Next
        </EcnButton>
      </div>
    </div>
  );
};

export default RoleAssign;
